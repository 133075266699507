import { Container } from "../../Commons/Container";
import { Card } from "./Card";

export const SignCard = (props) => {
  const {
    fields,
    fieldsHandler,
    onClick,
    active,
    loading,
    error,
    data,
    location,
  } = props;
  return (
    <Container bgColor="#F2F2F2">
      <Card
        fields={fields}
        fieldsHandler={fieldsHandler}
        onClick={onClick}
        active={active}
        loading={loading}
        error={error}
        data={data}
        location={location}
      />
    </Container>
  );
};
