import styled from "styled-components";

import { Container } from "../Container";
import { CustomT } from "../../../../Components/CustomT";

import { InfoBox } from "./InfoBox";
import { ChangePassword } from "./ChangePassword";

export const AccountDetails = (props) => {
  const { user } = props;

  return (
    <StyledContainer>
      <Title text="Account Details" weight="medium" />
      <InfoBox user={user} />
      <ChangePassword password={user.password} />
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  box-sizing: border-box;
  padding: 32px 24px;
`;

const Title = styled(CustomT)`
  font-size: 24px;
  line-height: 28px;
  color: #000;
  margin-bottom: 48px;
`;
