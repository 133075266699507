import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Header } from "../Commons/Header";
import { Bottom } from "../Commons/Bottom";
import { NavRoutes } from "./NavRoutes";
import { SignRoutes } from "./SignIn&UpRoutes";
import { Layout } from "../Layout";
import { ServiceTemplate } from "../pages/OurServices/ServiceTemplate";
import { SendRequest } from "../pages/SendRequest";
import { Profile } from "../pages/Profile";
export const RootNav = () => {
  return (
    <Router>
      <Header />
      <Switch>
        <Layout>
          <NavRoutes />
          <SignRoutes />
          <Route
            path="/service/:id"
            render={({ match }) => <ServiceTemplate match={match} />}
          />
          <Route path="/send-request" render={() => <SendRequest />} />
          <Route path="/profile" component={Profile} />
        </Layout>
      </Switch>
      <Bottom />
    </Router>
  );
};
