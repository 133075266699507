import styled from "styled-components";

import { Container } from "../Container";
import { Row } from "../../Components/Row";
import { Navigation } from "./Navigation";
import { Contact } from "./Contact";
import { Socials } from "./Socials";
import { Rights } from "./Rights";

export const Bottom = () => {
  return (
    <StyledContainer bgColor="#333333">
      <StyledRow>
        <Navigation />
        <Wrapper>
          <Contact />
          <Socials />
        </Wrapper>
      </StyledRow>
      <Rights />
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  padding: 56px 0 0 0;
`;

const StyledRow = styled(Row)`
  width: 100%;
  border-bottom: 1px solid #4f4f4f;
  padding-bottom: 56px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 -39px;
`;
