export const NOTIFICATION = {
  width: "20px",
  height: "20px",
  viewBox: "0 0 20 20",
  fill: "none",
  path: [
    {
      fill: "#EB5757",
      stroke: "none",
      strokeWidth: "0",
      d: "M15.6 14.6452H17V16.0968H3V14.6452H4.4V9.56451C4.4 8.02454 4.99 6.54765 6.0402 5.45873C7.09041 4.36981 8.51479 3.75806 10 3.75806C11.4852 3.75806 12.9096 4.36981 13.9598 5.45873C15.01 6.54765 15.6 8.02454 15.6 9.56451V14.6452ZM7.9 17.5484H12.1V19H7.9V17.5484Z",
    },
    {
      fill: "#EB5757",
      stroke: "none",
      strokeWidth: "0",
      d: "M11.26 1H8.73998V2.45161H11.26V1Z",
    },
  ],
};
