import styled from "styled-components";

import { CustomT } from "../../../../Components/CustomT";

export const InfoBox = (props) => {
  const { user } = props;
  const DUMMY = [
    {
      title: "First Name",
      info: user.name,
    },
    {
      title: "Last Name",
      info: user.lastName,
    },
    {
      title: "Company",
      info: user.company,
    },
    {
      title: "Position",
      info: user.position,
    },
    {
      title: "Phone",
      info: user.phone,
    },
    {
      title: "E-mail",
      info: user.email,
    },
  ];
  return (
    <Container>
      {DUMMY.map((item) => (
        <BoxWrapper>
          <BoxTitle text={item.title} />
          <Box>
            <Info text={item.info} />
          </Box>
        </BoxWrapper>
      ))}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const BoxWrapper = styled.div`
  display: flex;
  width: 49%;
  flex-direction: column;
  height: auto;
  margin-bottom: 16px;
`;

const BoxTitle = styled(CustomT)`
  font-size: 14px;
  line-height: 18px;
  color: #828282;
  margin-bottom: 8px;
`;

const Box = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 12px 16px;
  border: 1px solid #828282;
  border-radius: 6px;
`;

const Info = styled(CustomT)`
  font-size: 14px;
  line-height: 18px;
  color: #333333;
`;
