export const EXIT = {
  width: "20px",
  height: "20px",
  viewBox: "0 0 20 20",
  fill: "none",
  path: [
    {
      fill: "black",
      stroke: "none",
      strokeWidth: "0",
      d: "M6.3961 3.33334V5.28139H4.44805V15.0217H6.3961V16.9697H2.5V3.33334H6.3961ZM6.3961 11.1255V9.1775H13.7987L9.61039 4.98919L10.974 3.62555L17.5 10.1515L10.974 16.6775L9.61039 15.3139L13.7987 11.1255H6.3961Z",
    },
  ],
};
