import styled from "styled-components";

export const Image = (props) => {
  const { img } = props;
  return (
    <Container>
      <Img src={img} />
    </Container>
  );
};

const Container = styled.div`
  width: calc(50% - 15px);
  height: 412px;
  margin: 0 0 80px;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
`;
