import { useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { selectAuthStatus } from "../../../store/auth";
import { selectUserFullName } from "../../../store/auth";

import { NavBarLink } from "../../../Components/NavBarLink";
import { Row } from "../../../Components/Row";
import { SendRequest } from "./Sendrequest";
import { Profile } from "./Profile";
import { TABS } from "../../../navigation/navigationPages";
import { SIGNTABS } from "../../../navigation/SingIn&UpPages";

const active = {
  backgroundColor: "#f2f2f2",
};

const mapStateToProps = (state) => ({
  status: selectAuthStatus(state),
  fullName: selectUserFullName(state),
});

export const NavBar = connect(mapStateToProps)(({ status, fullName }) => {
  const [tabs, setTabs] = useState(TABS);

  let NavArr = [...TABS, ...SIGNTABS];
  if (status === true) {
    NavArr = [...TABS];
  }
  return (
    <Row>
      <SendRequest />
      {NavArr.map((item, index) => {
        if (item.name == "Log In" || item.name == "Register")
          return (
            <StyledNavBarLink
              key={index}
              weight="medium"
              to={item.to}
              name={item.name}
              active={active}
            />
          );
        return (
          <NavBarLink
            key={index}
            weight="medium"
            to={item.to}
            name={item.name}
          />
        );
      })}
      {status && <Profile fullName={fullName} />}
    </Row>
  );
});

const StyledNavBarLink = styled(NavBarLink)`
  :hover {
    background-color: #e0e0e0;
  }
`;
