import styled from "styled-components";
import { Container } from "../../../Commons/Container";
import { Titles } from "../../../Components/Titles";
import { CardList } from "./CardList";

export const HowItWorks = () => {
  return (
    <StyledContainer>
      <Titles
        titleColor="#2F80ED"
        title="How It Works"
        subtitle="Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis quis molestiae vitae eligendi at."
      />
      <CardList />
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  padding: 80px 0;
  background-color: #f4f6f8;
`;
