export const DUMMY = [
  {
    title: "First name",
    name: "name",
    type: "text",
    invalidMessage: "Please enter valid name",
  },
  {
    title: "Last name",
    name: "lastName",
    type: "text",
    invalidMessage: "Please enter valid last name",
  },
  {
    title: "E-mail",
    name: "email",
    type: "email",
    invalidMessage: "Please enter valid e-mail",
  },
  {
    title: "Phone",
    name: "phone",
    type: "phone",
    invalidMessage: "Please enter number",
  },
];

export const InputDummy = {
  title: "Subject",
  name: "subject",
  type: "text",
  invalidMessage: "Please enter Subject",
};
