import styled from "styled-components";

import { CustomT } from "../../Components/CustomT";
import { NavLink } from "react-router-dom";

export const Info = ({ title, text, to }) => {
  return (
    <Container>
      <Title text={title} weight="medium" />
      <Text text={text} />
      <StyledNavLink to={to}>Learn more</StyledNavLink>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: #ebf4ef;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 32px;
  margin-top: 16px;
`;

const Title = styled(CustomT)`
  font-size: 18px;
  line-height: 24px;
  color: #000;
  margin-bottom: 12px;
`;

const Text = styled(CustomT)`
  font-size: 14px;
  line-height: 18px;
  color: #4f4f4f;
  display: inline;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  font-size: 14px;
  line-height: 18px;
  color: #2f80ed;
`;
