import styled from "styled-components";
import { Container } from "../Container";
import { Logo } from "./Logo";
import { NavBar } from "./NavBar";

export const Header = () => {
  return (
    <StyledContainer>
      <Wrapper>
        <Logo />
        <NavBar />
      </Wrapper>
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  position: sticky;
  top: 0;
  -webkit-position: sticky;
  z-index: 200;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 84px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
