export const SOCIALS = [
  {
    width: "7px",
    height: "14px",
    fill: "none",
    viewBox: "0 0 7 14",
    path: [
      {
        fill: "#F2F2F2",
        stroke: "none",
        strokeWidth: 0,
        d: "M4.375 4.8125V3.0625C4.375 2.5795 4.767 2.1875 5.25 2.1875H6.125V0H4.375C2.92513 0 1.75 1.17513 1.75 2.625V4.8125H0V7H1.75V14H4.375V7H6.125L7 4.8125H4.375Z",
      },
    ],
  },
  {
    width: "14px",
    height: "14px",
    fill: "none",
    viewBox: "0 0 14 14",
    path: [
      {
        fill: "#F2F2F2",
        stroke: "none",
        strokeWidth: 0,
        d: "M3.88889 0C1.74144 0 0 1.74144 0 3.88889V10.1111C0 12.2586 1.74144 14 3.88889 14H10.1111C12.2586 14 14 12.2586 14 10.1111V3.88889C14 1.74144 12.2586 0 10.1111 0H3.88889ZM11.6667 1.55556C12.096 1.55556 12.4444 1.904 12.4444 2.33333C12.4444 2.76267 12.096 3.11111 11.6667 3.11111C11.2373 3.11111 10.8889 2.76267 10.8889 2.33333C10.8889 1.904 11.2373 1.55556 11.6667 1.55556ZM7 3.11111C9.14744 3.11111 10.8889 4.85256 10.8889 7C10.8889 9.14744 9.14744 10.8889 7 10.8889C4.85256 10.8889 3.11111 9.14744 3.11111 7C3.11111 4.85256 4.85256 3.11111 7 3.11111ZM7 4.66667C6.38116 4.66667 5.78767 4.9125 5.35008 5.35008C4.9125 5.78767 4.66667 6.38116 4.66667 7C4.66667 7.61884 4.9125 8.21233 5.35008 8.64992C5.78767 9.0875 6.38116 9.33333 7 9.33333C7.61884 9.33333 8.21233 9.0875 8.64992 8.64992C9.0875 8.21233 9.33333 7.61884 9.33333 7C9.33333 6.38116 9.0875 5.78767 8.64992 5.35008C8.21233 4.9125 7.61884 4.66667 7 4.66667Z",
      },
    ],
  },
  {
    width: "14px",
    height: "10px",
    fill: "none",
    viewBox: "0 0 14 10",
    path: [
      {
        fill: "#F2F2F2",
        stroke: "none",
        strokeWidth: 0,
        d: "M13.7581 1.56971C13.5867 0.825162 12.9777 0.275856 12.2446 0.193915C10.5085 0.000119353 8.75132 -0.000892207 7.00224 0.000119406C5.25287 -0.000892207 3.49541 0.000119353 1.75934 0.193915C1.02664 0.275856 0.418085 0.825162 0.246689 1.56971C0.00274581 2.63002 0 3.78745 0 4.87913C0 5.9708 0 7.12809 0.243943 8.18826C0.41505 8.93266 1.02375 9.48197 1.75688 9.56405C3.49281 9.75799 5.25013 9.759 6.99949 9.75799C8.74915 9.759 10.506 9.75799 12.2418 9.56405C12.9745 9.48211 13.5836 8.9328 13.755 8.18826C13.9991 7.12794 14 5.97066 14 4.87913C14 3.78745 14.002 2.63002 13.7581 1.56971ZM5.19333 7.05207C5.19333 5.50344 5.19333 3.97099 5.19333 2.42221C6.67708 3.19638 8.151 3.96535 9.64414 4.74429C8.15562 5.5163 6.68098 6.28064 5.19333 7.05207Z",
      },
    ],
  },
  {
    width: "14px",
    height: "12px",
    fill: "none",
    viewBox: "0 0 14 12",
    path: [
      {
        fill: "#F2F2F2",
        stroke: "none",
        strokeWidth: 0,
        d: "M14 1.35001C13.4852 1.57814 12.9321 1.73228 12.3507 1.80196C12.9506 1.44298 13.3994 0.877996 13.6134 0.212422C13.0498 0.547217 12.4329 0.782876 11.7896 0.909154C11.357 0.447255 10.784 0.1411 10.1595 0.0382231C9.53511 -0.0646541 8.89419 0.0415017 8.33627 0.340209C7.77835 0.638917 7.33466 1.11346 7.07408 1.69017C6.81351 2.26689 6.75062 2.91349 6.89518 3.52961C5.75309 3.47226 4.63581 3.17541 3.61587 2.65833C2.59593 2.14124 1.69611 1.41546 0.974808 0.52811C0.728177 0.953548 0.586365 1.44681 0.586365 1.97213C0.58609 2.44505 0.702548 2.91071 0.925407 3.32782C1.14827 3.74493 1.47064 4.10058 1.86391 4.36322C1.40782 4.34871 0.961782 4.22547 0.562935 4.00376V4.04075C0.562889 4.70403 0.792322 5.34689 1.2123 5.86027C1.63229 6.37364 2.21695 6.7259 2.86708 6.85727C2.44398 6.97178 2.00038 6.98865 1.56981 6.9066C1.75324 7.47731 2.11054 7.97638 2.5917 8.33393C3.07286 8.69148 3.65378 8.88962 4.25315 8.90061C3.23569 9.69933 1.97914 10.1326 0.685634 10.1307C0.456502 10.1307 0.227564 10.1174 0 10.0906C1.31299 10.9348 2.8414 11.3828 4.40236 11.3811C9.68643 11.3811 12.5751 7.00464 12.5751 3.20899C12.5751 3.08567 12.572 2.96112 12.5665 2.83781C13.1283 2.43147 13.6134 1.92829 13.9988 1.35186L14 1.35001Z",
      },
    ],
  },
  {
    width: "14px",
    height: "14px",
    fill: "none",
    viewBox: "0 0 14 14",
    path: [
      {
        fill: "#F2F2F2",
        stroke: "none",
        strokeWidth: 0,
        d: "M1.68261 3.37097C2.6119 3.37097 3.36523 2.61635 3.36523 1.68548C3.36523 0.754617 2.6119 0 1.68261 0C0.753332 0 0 0.754617 0 1.68548C0 2.61635 0.753332 3.37097 1.68261 3.37097Z",
      },
      {
        fill: "#F2F2F2",
        stroke: "none",
        strokeWidth: 0,
        d: "M4.95402 4.64818V13.9992H7.85246V9.37493C7.85246 8.15473 8.08162 6.97304 9.59198 6.97304C11.0816 6.97304 11.1 8.36811 11.1 9.45196V14H14V8.87191C14 6.35292 13.4586 4.41708 10.5194 4.41708C9.10827 4.41708 8.16237 5.1928 7.77556 5.92693H7.73634V4.64818H4.95402ZM0.230706 4.64818H3.13375V13.9992H0.230706V4.64818Z",
      },
    ],
  },
];
