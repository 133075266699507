import styled from "styled-components";

export const Layout = (props) => {
  const { children } = props;
  return <Body>{children}</Body>;
};

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 64vh;
`;
