import { useState } from "react";
import styled from "styled-components";

import { Icon } from "../../Components/Icon";
import { DELETE } from "../../assets/delete";

export const BinIcon = ({ canDelete, ...rest }) => {
  const [fillColor, setFillColor] = useState("#4F4F4F");

  const onMouseOver = () => {
    canDelete && setFillColor("red");
  };
  const onMouseLeave = () => {
    setFillColor("#4F4F4F");
  };
  return (
    <IconWrapper
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      isHover={canDelete}
      {...rest}
    >
      <Icon {...DELETE} fillColor={fillColor} />
    </IconWrapper>
  );
};

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
  :hover {
    background: ${(props) => props.isHover && "rgba(235, 87, 87, 0.1)"};
  }
  position: relative;
`;
