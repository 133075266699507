import styled from "styled-components";

export const Container = ({ children, className }) => {
  return <Main className={className}>{children}</Main>;
};

const Main = styled.div`
  width: 67%;
  min-height: 45vh;
  background-color: #fff;
  border-radius: 8px;
`;
