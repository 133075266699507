import { NavLink } from "react-router-dom";
import { CustomT } from "./CustomT";
import styled from "styled-components";

export const NavBarLink = (props) => {
  const { to, name, style, textStyle, active, weight, className, ...rest } =
    props;
  return (
    <StyledNavLink
      style={style}
      className={className}
      exact
      to={to}
      activeStyle={active ? active : isActive}
      {...rest}
    >
      <CustomT
        text={name}
        weight={weight ? weight : "regular"}
        style={textStyle}
      />
    </StyledNavLink>
  );
};

const StyledNavLink = styled(NavLink)`
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  text-decoration: none;
  background-color: transparent;
  border-radius: 4px;
  color: black;
  margin-left: 16px;
`;

const isActive = {
  backgroundColor: "#27ae60",
  color: "#fff",
};
