export const INFODUMMY = {
  worker: {
    title: "What is team? worker",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Qui fuga ipsa, repellat blanditiis nihil, consectetur. Consequuntur eum inventore, rem maxime, nisi excepturi ipsam libero ratione adipisci alias eius vero vel! Lorem ipsum dolor sit amet, consectetur adipisicing elit. worker",
    to: "./contact",
  },
  team: {
    title: "What is team? team",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Qui fuga ipsa, repellat blanditiis nihil, consectetur. Consequuntur eum inventore, rem maxime, nisi excepturi ipsam libero ratione adipisci alias eius vero vel! Lorem ipsum dolor sit amet, consectetur adipisicing elit. team",
    to: "./",
  },
  service: {
    title: "What is team? serivce",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Qui fuga ipsa, repellat blanditiis nihil, consectetur. Consequuntur eum inventore, rem maxime, nisi excepturi ipsam libero ratione adipisci alias eius vero vel! Lorem ipsum dolor sit amet, consectetur adipisicing elit. serivce",
    to: "./",
  },
};
