import styled from "styled-components";

import { CustomT } from "../../../Components/CustomT";
import { NavBarLink } from "../../../Components/NavBarLink";

export const Content = (props) => {
  const { text, navText, to } = props;
  return (
    <Container>
      <Title text={text} weight="bold" />
      <Link to={to} name={navText} weight="medium" textStyle={textStyle} />
    </Container>
  );
};

const Container = styled.div`
  width: 470px;
  position: absolute;
  top: 152px;
  left: 165px;
  display: flex;
  flex-direction: column;
`;

const Title = styled(CustomT)`
  font-size: 44px;
  line-height: 51.56px;
  color: #fff;
`;

const Link = styled(NavBarLink)`
  margin: 0;
  padding: 12px 32px;
  align-self: flex-start;
  margin-top: 60px;
  background-color: #2f80ed;
`;

const textStyle = {
  fontSize: "16px",
  lineHeight: "24px",
  color: "#fff",
};
