import { useState, useEffect, useRef } from "react";

import styled from "styled-components";
import { ContainerWithTitle } from "../../Commons/ContainerWithTitle";
import { InputsBlock } from "../../Components/InputsBlock";
import { Button } from "../../Components/Button";
import { Textarea } from "../../Components/Textarea";
import { validate } from "../../utils/validate";
import { checkEmail } from "../../utils/emailRegex";
import { checkPhone } from "../../utils/phoneRegex";
import { DUMMY, InputDummy } from "./dummy";
import { sendContact } from "../../store/contact";
import { Modal } from "../../Components/Modal";
import { Input } from "../../Components/Input";
import { useUserState } from "../../hooks/getUserState";
import { useUserInfo } from "../../hooks/getUserInfo";

import ReCAPTCHA from "react-google-recaptcha";
import { TestSiteKey, ProdSiteKey } from "./apiKeys";

const initialFields = {
  name: "",
  lastName: "",
  email: "",
  phone: "",
  subject: "",
  message: "",
};

export const Contact = () => {
  const [fields, setFields] = useState(initialFields);
  const [active, setActive] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const [loading, setLoading] = useState(false);
  const modalRef = useRef();
  const userStatus = useUserState();
  const userInfo = useUserInfo();

  //CHECK FOR FIELDS VALIDATIONS
  useEffect(() => {
    const validEmail = !checkEmail(fields.email);
    const validPhone = !checkPhone(fields.phone);
    const restFeildvalid = validate(fields);

    userStatus
      ? validate({ subject: fields.subject, message: fields.message })
        ? setActive(true)
        : setActive(false)
      : validEmail && validPhone && restFeildvalid && captcha
      ? setActive(true)
      : setActive(false);
  }, [fields, captcha]);

  //FIELDS HANDLER
  const fieldsHandler = (name, value) => {
    setFields((fields) => ({
      ...fields,
      [name]: value,
    }));
  };

  //SEND REQUEST HADNLER
  const sendRequestHandler = async () => {
    if (active && captcha) {
      setLoading(true);
      const res = await sendContact(
        userStatus
          ? { ...userInfo, subject: fields.subject, message: fields.message }
          : fields
      );
      if (res) {
        window.scrollTo({
          top: 0,
          behavior: "auto",
        });
        modalRef.current.open();
        setFields(initialFields);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <ContainerWithTitle
        title="Contact"
        subtitle="Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis quis molestiae vitae eligendi at."
      >
        <Modal
          ref={modalRef}
          title="Your request has been sended !"
          subtTitle="You have succesfully signed up! Start to create your resume."
        />
        {!userStatus && (
          <InputsBlock
            data={DUMMY}
            fieldsHandler={fieldsHandler}
            fields={fields}
          />
        )}

        <StyledInput
          {...InputDummy}
          value={fields.subject}
          onChange={(e) => fieldsHandler(e.target.name, e.target.value)}
        />
        <Textarea value={fields.message} fieldsHandler={fieldsHandler} />
        <ReCAPTCHA sitekey={ProdSiteKey} onChange={() => setCaptcha(true)} />
        <StyledButton
          text="Send Request"
          weight="medium"
          bgColor={active && captcha ? "#27AE60" : "#e0e0e0"}
          cursor={active ? "pointer" : "default"}
          color={active ? "#fff" : "#333"}
          onClick={sendRequestHandler}
          loading={loading}
        />
      </ContainerWithTitle>
    </>
  );
};

const StyledButton = styled(Button)`
  background-color: ${(props) => props.bgColor};
  cursor: ${(props) => props.cursor};
  padding: 12px 32px;
  margin-top: 20px;
  width: 32%;
  div {
    font-size: "16px";
    color: ${(props) => props.color};
  }
`;

const StyledInput = styled(Input)`
  width: 100%;
  margin-bottom: 24px;
`;
