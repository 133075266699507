import styled from "styled-components";
import { CustomT } from "../../../Components/CustomT";

export const Card = (props) => {
  const { step, stepTitle, stepSubtitle } = props;
  return (
    <Container>
      <Circle>
        <StyledNumber text={step} weight="medium" />
      </Circle>
      <Title text={stepTitle} weight="medium" />
      <SubTitle text={stepSubtitle} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  margin: 56px 15px 0;
  padding: 0 10px 0 0;
  box-sizing: border-box;
`;

const Circle = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #052b5f;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledNumber = styled(CustomT)`
  font-size: 22px;
  line-height: 25.78px;
  color: #fff;
`;

const Title = styled(CustomT)`
  font-size: 22px;
  line-height: 25.78px;
  color: #333333;
  margin: 24px 0;
`;

const SubTitle = styled(CustomT)`
  font-size: 18px;
  line-height: 26px;
  color: #828282;
`;
