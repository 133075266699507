import styled from "styled-components";

import { colorGenrator } from "../utils/generateColor";
import { CustomT } from "./CustomT";

export const ProfileCircle = (props) => {
  const { width, fullName, size, color, statusCircle, ...rest } = props;
  console.log("full name", fullName);

  const firstLetter = fullName.charAt(0);
  const imageBackgroundColor = colorGenrator();
  return (
    <ImageWrapper
      col={imageBackgroundColor}
      width={width ? width : "36px"}
      margin={statusCircle}
    >
      <FirstName
        text={firstLetter}
        weight="bold"
        size={size ? size : "16px"}
        {...rest}
      />
    </ImageWrapper>
  );
};

const ImageWrapper = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.width};
  border-radius: 50%;
  background-color: ${(props) => props.col};
  margin: ${(props) => (props.margin ? "0" : "0 12px 0 16px")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FirstName = styled(CustomT)`
  font-size: ${(props) => props.size};
  line-height: 24px;
  color: #fff;
`;
