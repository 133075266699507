import styled from "styled-components";

import { CustomT } from "../../Components/CustomT";

export const Rights = () => {
  return <Text text="2021 © GetContact" />;
};

const Text = styled(CustomT)`
  font-size: 14px;
  line-height: 24px;
  color: #828282;
  text-align: center;
  margin: 20px 0;
`;
