export const DROPDOWN = {
  width: "17px",
  height: "16px",
  fill: "none",
  viewBox: "0 0 17 16",
  path: [
    {
      fill: "black",
      stroke: "none",
      strokeWidth: 0,
      d: "M8.29302 10.7721L4.8462 7.10814C4.43942 6.67705 4.77002 6 5.38737 6H12.281C12.4192 5.99989 12.5545 6.03682 12.6706 6.10636C12.7868 6.17591 12.879 6.27512 12.9361 6.39212C12.9932 6.50912 13.0128 6.63895 12.9926 6.76606C12.9724 6.89317 12.9132 7.01217 12.8222 7.10881L9.37537 10.7714C9.30791 10.8432 9.22472 10.9008 9.13138 10.9402C9.03804 10.9796 8.93671 11 8.8342 11C8.73168 11 8.63035 10.9796 8.53701 10.9402C8.44367 10.9008 8.36048 10.8432 8.29302 10.7714V10.7721Z",
    },
  ],
};
