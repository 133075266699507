import { useState, useEffect } from "react";
import { connect } from "react-redux";

import { SignCard } from "../../Components/SignCard/Index";
import { DUMMY } from "./dummy";

import { checkEmail } from "../../utils/emailRegex";
import { validate } from "../../utils/validate";
import { checkPhone } from "../../utils/phoneRegex";
import { getErrorMessage } from "../../utils/getErrorMessage";

import { setUser } from "../../store/auth";
import { selectAuthStatus } from "../../store/auth";

const initialValues = {
  name: "",
  lastName: "",
  company: "",
  position: "",
  phone: "",
  email: "",
  password: "",
  retypePassword: "",
};

const mapStateToProps = (state) => ({
  status: selectAuthStatus(state),
});

export const Register = connect(mapStateToProps, { setUser })(
  ({ setUser, history, status }) => {
    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: "auto",
      });
    }, []);

    const [fields, setFields] = useState(initialValues);
    const [active, setActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({ status: false, errorMessage: "" });

    const fieldsHandler = (name, value) => {
      setFields((fields) => ({
        ...fields,
        [name]: value,
      }));
    };

    useEffect(() => {
      const correctPhone = !checkPhone(fields.phone);
      const correctEmail = !checkEmail(fields.email);
      const correctPairPassword =
        fields.password === fields.retypePassword &&
        fields.retypePassword.trim() !== "";
      const validFeilds = validate(fields);

      const valid =
        correctPairPassword && correctPhone && correctEmail && validFeilds;
      valid ? setActive(true) : setActive(false);
    }, [fields]);

    useEffect(() => {
      if (status) {
        setLoading(false);
        history.push("profile");
      }
    }, [status]);

    const registerHandler = async () => {
      if (active) {
        setLoading(true);
        const answer = await setUser(fields);

        if (answer?.code) {
          setLoading(false);
          const errorMessage = getErrorMessage(answer.code);
          window.scrollTo({
            top: 0,
            behavior: "auto",
          });
          setError({ status: true, errorMessage });
        }
      }
    };

    return (
      <SignCard
        fields={fields}
        fieldsHandler={fieldsHandler}
        active={active}
        loading={loading}
        onClick={registerHandler}
        error={error}
        data={DUMMY}
        location="register"
      />
    );
  }
);
