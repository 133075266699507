export const DUMMY = [
  {
    title: "First Name",
    name: "name",
    type: "text",
    invalidMessage: "Enter correct name",
  },
  {
    title: "Last Name",
    name: "lastName",
    type: "text",
    invalidMessage: "Enter correct last name",
  },
  {
    title: "Company",
    name: "company",
    type: "text",
    invalidMessage: "Enter correct company name",
  },
  {
    title: "Position",
    name: "position",
    type: "text",
    invalidMessage: "Enter correct position",
  },

  {
    title: "Phone",
    name: "phone",
    type: "phone",
    invalidMessage: "Enter correct phone number",
  },
  {
    title: "Enterprise e-mail",
    name: "email",
    type: "email",
    invalidMessage: "Enter correct email",
  },
  {
    title: "Password",
    name: "password",
    type: "password",
    invalidMessage: "Enter at least 6 carchecter",
  },
  {
    title: "Retype passowrd",
    name: "retypePassword",
    type: "password",
    invalidMessage: "Enter at least 6 carchecter",
  },
];
