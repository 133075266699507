import { useState } from "react";

import styled from "styled-components";

import { Details } from "./Details";
import { InputsBlock } from "../../Components/InputsBlock";

export const Contact = () => {
  const [fields, setFields] = useState({
    name: "",
    lastName: "",
    company: "",
    position: "",
    phone: "",
    email: "",
  });

  const fieldsHandler = (name, value) => {
    setFields((fields) => ({
      ...fields,
      [name]: value,
    }));
  };

  const data = [
    {
      title: "Firts Name",
      name: "name",
    },
    {
      title: "Last Name",
      name: "lastName",
    },
    {
      title: "Company",
      name: "company",
    },
    {
      title: "Position",
      name: "position",
    },
    {
      title: "Phone",
      name: "phone",
    },
    {
      title: "Email",
      name: "email",
    },
  ];
  return (
    <Container>
      <Details text="Contact Details" />
      <Inputs
        fields={fields}
        fieldsHandler={fieldsHandler}
        data={data}
        margin="16px"
      />
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 40px;
  padding-top: 16px;
`;

const Inputs = styled(InputsBlock)`
  margin-top: 36px;
`;
