import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { CustomT } from "../../../Components/CustomT";
import { ProfileCircle } from "../../../Components/ProfileCircle";

import { Icon } from "../../../Components/Icon";
import { NOTIFICATION } from "../../../assets/notification";

export const Profile = (props) => {
  const { fullName } = props;
  return (
    <Container>
      <NotificationCircle>
        <Icon {...NOTIFICATION} />
        <CountContainer>
          <Count text="2" weight="bold" />
        </CountContainer>
      </NotificationCircle>
      <StyledNavLink to="/profile">
        <ProfileCircle width="36px" fullName={fullName} />
        <Name text={fullName} weight="medium" />
      </StyledNavLink>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const NotificationCircle = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #fdeded;
  margin: 0 0 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
`;

const CountContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #eb5757;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Count = styled(CustomT)`
  font-size: 8px;
  line-height: 9.38px;
  color: #fff;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Name = styled(CustomT)`
  font-size: 14px;
  line-height: 24px;
  color: #000;
`;
