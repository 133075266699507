import styled from "styled-components";

import { Title } from "./Title";
import { SwipperContainer } from "./SwipperContainer";

export const FeedBacks = () => {
  const DUMMY = [
    {
      comment:
        "“Lorem ipsum dolor sit amet consectetur adipisicing elit. Et praesentium eos nulla qui commodi consectetur beatae fugiat. Veniam iste rerum perferendis.Lorem ipsum dolor sit amet consectetur adipisicing elit. Et praesentium eos nulla qui commodi consectetur beatae fugiat. Veniam iste rerum perferendis.”",
      name: "Name SureName",
      position: "CEO & Owner",
    },
    {
      comment:
        "“Lorem ipsum dolor sit amet consectetur adipisicing elit. Et praesentium eos nulla qui commodi consectetur beatae fugiat. Veniam iste rerum perferendis.Lorem ipsum dolor sit amet consectetur adipisicing elit. Et praesentium eos nulla qui commodi consectetur beatae fugiat. Veniam iste rerum perferendis.”",
      name: "Name SureName",
      position: "CEO & Owner",
    },
  ];

  return (
    <Container>
      <Title />
      <SwipperContainer data={DUMMY} />
    </Container>
  );
};

const Container = styled.div`
  width: calc(100% - 22%);
  margin: auto;
  padding: 80px 0;
`;
