import { TABS } from "./navigationPages";
import { Route } from "react-router-dom";

export const NavRoutes = () => {
  return (
    <>
      {TABS.map((item, index) => {
        const TagName = item.tab;

        return <Route key={index} exact path={item.to} component={TagName} />;
      })}
    </>
  );
};
