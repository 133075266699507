import styled from "styled-components";

import { CustomT } from "../../../Components/CustomT";
import { NavBarLink } from "../../../Components/NavBarLink";

export const Card = (props) => {
  const { title, subtitle, to } = props;
  return (
    <Wrapper>
      <CustomT text={title} weight="medium" style={titleStyle} />
      <CustomT style={subTitle} text={subtitle} />
      <NavBarLink
        to={to}
        name="Learn more"
        style={navLink}
        textStyle={navText}
      />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  height: auto;
  width: auto;
  margin: 0 15px;
  border-radius: 8px;
  background: #f4f6f8;
  display: flex;
  flex-direction: column;
  padding: 24px;
  box-sizing: border-box;
`;

const titleStyle = {
  fontSize: "22px",
  lineHeight: "25.78px",
  color: "#333333",
};

const subTitle = {
  fontSize: "18px",
  lineHeight: "26px",
  color: "#828282",
  marginTop: "24px",
};

const navLink = {
  padding: "0",
  margin: "0",
  width: "fit-content",
  marginTop: "24px",
  backgroundColor: "transparent",
};

const navText = {
  fontSize: "18px",
  lineHeight: "26px",
  color: "#27AE60",
};
