import styled from "styled-components";

import ClipLoader from "react-spinners/ClipLoader";

import { Button } from "./Button";
import { CustomT } from "./CustomT";
import { NavBarLink } from "./NavBarLink";

export const SignInSignUp = (props) => {
  const { className, btnName, navName, bgColor, to, ...rest } = props;
  return (
    <Wrapper className={className}>
      <StyledButton text={btnName} weight="medium" {...rest} col={bgColor} />
      <ClipLoader color="green" loading={false} size={150} />
      <TextWrapper>
        <Or text="or" weight="medium" />
        <Nav
          name={navName}
          to={to}
          weight="medium"
          active={{ backgroundColor: "#fff" }}
        />
      </TextWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;
const StyledButton = styled(Button)`
  box-sizing: border-box;
  width: 100%;
  background-color: ${(props) => props.col};
  text-align: center;
  align-self: center;
  color: #fff;
  padding: 12px 24px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  margin-top: 24px;
`;

const Or = styled(CustomT)`
  font-size: 16px;
  color: #838383;
  margin-right: 3.5px;
`;

const Nav = styled(NavBarLink)`
  font-size: 16px;
  color: #3580e4;
  cursor: pointer;
  margin: 0;
  padding: 0;
  background-color: #fff;
`;
