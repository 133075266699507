import styled from "styled-components";

import { NavLink } from "react-router-dom";
import { CustomT } from "../../../Components/CustomT";

export const Tabs = () => {
  const Links = [
    {
      name: "Requests",
      to: "/profile/requests",
    },
    {
      name: "Notification",
      to: "/profile/notifications",
    },
    {
      name: "Account Details",
      to: "/profile",
    },
  ];

  return (
    <Container>
      {Links.map((link) => (
        <LinkWrapper>
          <Link exact to={link.to} activeStyle={{ color: "#27AE60" }}>
            <LinkText text={link.name} weight="medium" />
          </Link>
          {link.name === "Notification" && (
            <NotificationCircle>
              <NotificationText text="2" weight="medium" />
            </NotificationCircle>
          )}
        </LinkWrapper>
      ))}
    </Container>
  );
};

const Container = styled.div`
  padding: 4px 24px;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); ;
`;

const LinkWrapper = styled.div`
  margin: 24px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Link = styled(NavLink)`
  text-decoration: none;
  color: #333333;
`;

const LinkText = styled(CustomT)`
  font-size: 16px;
  line-height: 24px;
  color: inherit;
`;

const NotificationCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eb5757;
  margin-left: 11px;
`;

const NotificationText = styled(CustomT)`
  font-size: 10px;
  line-height: 12px;
  color: #fff;
`;
