export const getErrorMessage = (error) => {
  if (error === "auth/user-not-found") {
    return "There is no user, please register";
  }

  if (error === "auth/wrong-password") {
    return "Please enter correct password";
  }

  if (error === "auth/email-already-in-use") {
    return "This email already taken!";
  }
};
