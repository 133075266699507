import { useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { Container } from "../../Commons/Container";
import { LeftSide } from "./LeftSide/LeftSide";
import { RightSide } from "./RightSide";
import { selectUser, selectUserFullName } from "../../store/auth";
import { resetPassword } from "../../store/auth";

const mapStateToProps = (state) => ({
  user: selectUser(state),
  fullName: selectUserFullName(state),
});

export const Profile = connect(mapStateToProps, { resetPassword })(
  ({ user, fullName, resetPassword }) => {
    return (
      <StyledContainer>
        <Wrapper>
          <LeftSide {...user} fullName={fullName} />
          <RightSide user={user} />
        </Wrapper>
      </StyledContainer>
    );
  }
);

const StyledContainer = styled(Container)`
  background-color: #f2f2f2;
  min-height: 64vh;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 100px;
  width: 100%;
`;
