import styled from "styled-components";

import { CustomT } from "../../Components/CustomT";
import { NavBarLink } from "../../Components/NavBarLink";

export const Service = (props) => {
  const { title, sortDesc, id } = props;
  return (
    <Container>
      <Title text={title} weight="bold" />
      <Text text={sortDesc} />
      <Link
        exact
        to={`/service/${id}`}
        name="Learn more"
        textStyle={linkStyle}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  box-sizing: border-box;
  background-color: #f4f6f8;
  width: calc(50% - 15px);
  margin: 0 0 80px;
`;
const Title = styled(CustomT)`
  font-size: 26px;
  line-height: 30.47px;
  color: #333;
`;

const Text = styled(CustomT)`
  font-size: 16px;
  max-width: 460px;
  margin: 30px 0;
`;

const Link = styled(NavBarLink)`
  background-color: transparent;
  padding: 0;
  margin: 0;
  color: #27ae60;
`;

const linkStyle = {
  fontSize: "18px",
  lineHeight: "26px",
};
