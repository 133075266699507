import styled from "styled-components";

import { Icon } from "../../../Components/Icon";
import { EXIT } from "../../../assets/exit";
import { CustomT } from "../../../Components/CustomT";

export const ExitButton = () => {
  return (
    <Exit>
      <Button>
        <Icon {...EXIT} />
        <ButtonText text="Exit" weight="medium" />
      </Button>
    </Exit>
  );
};

const Exit = styled.div`
  padding: 24px;
`;

const Button = styled.div`
  padding: 8px 24px 8px 20px;
  background-color: #f2f2f2;
  border-radius: 6px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const ButtonText = styled(CustomT)`
  font-size: 16px;
  color: #333;
  margin-left: 8px;
`;
