import { Register } from "../pages/Register";
import { SingIn } from "../pages/SignIn";

export const SIGNTABS = [
  {
    name: "Log In",
    to: "/login",
    tab: SingIn,
  },
  {
    name: "Register",
    to: "/register",
    tab: Register,
  },
];
