import styled from "styled-components";
import { PARTNERS } from "../../../assets/partners";
import { Partner } from "./Partner";
import { Row } from "../../../Components/Row";

export const PartnerList = () => {
  return (
    <StyledRow>
      {PARTNERS.map((partner, index) => (
        <Partner key={index} icon={partner} />
      ))}
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  margin: 56px -15px 0 -15px;
`;
