import styled from "styled-components";

import { SOCIALS } from "../../assets/socials";
import { SvgIcon } from "./SvgIcon";

export const Socials = () => {
  return (
    <Container>
      {SOCIALS.map((item, index) => (
        <SvgIcon key={index} item={item} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 28px;
`;
