import { useState, forwardRef, useImperativeHandle } from "react";
import styled from "styled-components";

import { motion, AnimatePresence } from "framer-motion";
import { CustomT } from "./CustomT";
import success from "../assets/success.svg";
import { Button } from "./Button";

export const Modal = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const { title, subTitle } = props;

  useImperativeHandle(ref, () => {
    return {
      open: () => setOpen(true),
      close: () => setOpen(false),
    };
  });

  return (
    <AnimatePresence>
      {open && (
        <>
          <Backdrop
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.3 } }}
            exit={{ opacity: 0, transition: { delay: 0.3 } }}
            onClick={() => setOpen(false)}
          />
          <Wrapper
            initial={{ scale: 0 }}
            animate={{ scale: 1, transition: { duration: 0.3 } }}
            exit={{ scale: 0, transition: { delay: 0.3 } }}
          >
            <Image
              src={success}
              initial={{ scale: 0 }}
              animate={{ scale: 1, transition: { delay: 0.3, duration: 0.3 } }}
              exit={{ scale: 0, transition: { duration: 0.3 } }}
            />
            <Title text={title} weight="medium" />
            <Subtitle text={subTitle} />
            <StyledButton text="Close" onClick={() => setOpen(false)} />
          </Wrapper>
        </>
      )}
    </AnimatePresence>
  );
});

const Backdrop = styled(motion.div)`
  position: fixed;
  margin: auto;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 200;
`;
const Wrapper = styled(motion.div)`
  position: fixed;
  width: fit-content;
  height: fit-content;
  background: white;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0 32px;
  border-radius: 8px;
  z-index: 300;
`;

const Title = styled(CustomT)`
  font-size: 28px;
  line-height: 34px;
  color: #000;
  margin-bottom: 30px;
`;

const Subtitle = styled(CustomT)`
  margin-top: 14px;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #838383;
  max-width: 416px;
`;

const Image = styled(motion.img)`
  display: block;
  margin: auto;
  margin: 36px auto;
`;

const StyledButton = styled(Button)`
  background-color: #e0e0e0;
  margin: 32px auto;
  padding: 12px 32px;
  width: fit-content;
`;
