import styled from "styled-components";

import { CustomT } from "../../../Components/CustomT";

export const Title = () => {
  return <Text text="What People Say" weight="bold" />;
};

const Text = styled(CustomT)`
  font-size: 48px;
  line-height: 56.25px;
  color: #333;
  text-align: center;
`;
