import styled from "styled-components";
import { Card } from "./Card";
import { Row } from "../../../Components/Row";

export const CardList = (props) => {
  const { data } = props;
  return (
    <StyledRow>
      {data.map((item, index) => (
        <Card key={index} {...item} />
      ))}
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  margin: 56px -15px 0;
`;
