import styled from "styled-components";
import { CustomT } from "./CustomT";

import { motion, AnimatePresence } from "framer-motion";

export const Error = (props) => {
  const { errorMessage, opened } = props;
  return (
    <AnimatePresence>
      {opened && (
        <Container
          initial={{ opacity: 0, transition: { duration: 0.3 } }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0, transition: { duration: 0.3 } }}
        >
          <Text weight="medium" text={errorMessage} />
        </Container>
      )}
    </AnimatePresence>
  );
};

const Container = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 16px;
  background-color: #eb5757;
  border-radius: 6px;
  width: fit-content;
  align-self: center;
`;
const Text = styled(CustomT)`
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  text-align: center;
`;
