import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { Button } from "../../../../Components/Button";
import { Input } from "../../../../Components/Input";
import { Modal } from "../../../../Components/Modal";
import { checkPassword } from "../../../../utils/passwordRegex";

import { resetPassword } from "../../../../store/auth";

const initialFields = {
  currentPassword: "",
  newPassword: "",
  reTypeNewPassword: "",
};

export const ChangePassword = connect(null, { resetPassword })(
  ({ password, resetPassword }) => {
    const [fields, setFields] = useState(initialFields);

    const [show, setShow] = useState(false);
    const [active, setActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const modalRef = useRef();

    const fieldsHandler = (name, value) => {
      setFields((fields) => ({
        ...fields,
        [name]: value,
      }));
    };

    useEffect(() => {
      const isActive =
        !checkPassword(fields.newPassword) &
        !checkPassword(fields.reTypeNewPassword) &
        (fields.newPassword === fields.reTypeNewPassword) &
        ((fields.newPassword.trim() !== "") &
          (fields.currentPassword.trim() !== "") &
          (fields.currentPassword === password) &
          (fields.newPassword !== password));
      isActive ? setActive(true) : setActive(false);
    }, [fields]);

    const changePasswordHandler = async () => {
      if (active) {
        setLoading(true);
        const res = await resetPassword(fields.newPassword);
        if (res === "success") {
          setLoading(false);
          modalRef.current.open();
          setFields(initialFields);
          setShow(false);
        } else {
          setLoading(false);
          setShow(false);
          setFields(initialFields);
          alert("Something went wrong try again");
        }
      }
    };

    return (
      <Container>
        <StyledButton
          text="Change Password"
          weight="medium"
          onClick={() => setShow((show) => !show)}
        />

        <Modal
          ref={modalRef}
          title="Password successfull changed"
          subTitle="Your password changed, next time please use new password"
        />
        {show && (
          <>
            <Hr />
            <StyledInput
              name="currentPassword"
              title="Current Password"
              type="password"
              value={fields.currentPassword}
              invalidMessage="Password should be at least 6 character"
              onChange={(e) => fieldsHandler("currentPassword", e.target.value)}
            />
            <InputRow>
              <StyledInput
                name="currentPassword"
                title="New Password"
                type="password"
                value={fields.newPassword}
                invalidMessage="Password should be at least 6 character"
                onChange={(e) => fieldsHandler("newPassword", e.target.value)}
              />
              <StyledInput
                name="currentPassword"
                title="Retype new Password"
                type="password"
                value={fields.reTypeNewPassword}
                invalidMessage="Password should be at least 6 character"
                onChange={(e) =>
                  fieldsHandler("reTypeNewPassword", e.target.value)
                }
              />
            </InputRow>
            <SaveButton
              text="Save Changes"
              active={active}
              loading={loading}
              onClick={changePasswordHandler}
            />
          </>
        )}
      </Container>
    );
  }
);

const Container = styled.div`
  width: 100%;
`;

const StyledButton = styled(Button)`
  width: fit-content;
  background-color: #4f4f4f;
  color: #fff;
  padding: 12px 24px;
  margin-top: 32px;
  div {
    font-size: 16px;
    line-height: 24px;
  }
`;

const Hr = styled.hr`
  width: 100%;
  border: 1px solid #e0e0e0;
  margin: 40px 0 23px;
`;

const StyledInput = styled(Input)`
  width: 49%;
  margin-bottom: 16px;
`;

const InputRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const SaveButton = styled(StyledButton)`
  background-color: ${(props) => (props.active ? "#27AE60" : "#e0e0e0")};
  color: ${(props) => (props.active ? "#fff" : "#828282")};
`;
