import styled from "styled-components";
import { FONTS } from "../styles/fonts";
import { returnStyle } from "../utils/returnStyle";

export const CustomT = (props) => {
  const { weight, color, style, text, className, ...rest } = props;
  const size = weight ? FONTS[weight] : FONTS.regular;
  return (
    <CustomText
      style={returnStyle(style)}
      fontWeight={size}
      className={className}
      {...rest}
    >
      {text}
    </CustomText>
  );
};

const CustomText = styled.div`
  font-family: Roboto, serif;
  font-weight: ${(props) => props.fontWeight};
  line-height: 24px;
  font-size: 14px;
  color: "#000";
`;
