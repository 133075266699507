import { useEffect, useState } from "react";
import styled from "styled-components";

import { SmallTitle } from "../../Components/SmallTitle";
import { Details } from "./Details";
import { Contract } from "./ContractSection";
import { Button } from "../../Components/Button";
import { Contact } from "./ContactSection";
import { EMPLOYEESDUMMY, SERVICEDUMMY } from "./dummy";
import { Comment } from "./Comment";

import { useUserState } from "../../hooks/getUserState";

export const SendRequest = () => {
  const [dataType, setDataType] = useState("worker");
  const [data, setData] = useState(EMPLOYEESDUMMY);
  const userStatus = useUserState();

  useEffect(() => {
    if (dataType === "worker") setData(EMPLOYEESDUMMY);
    if (dataType === "team") setData([...EMPLOYEESDUMMY, ...EMPLOYEESDUMMY]);
    if (dataType === "service") setData(SERVICEDUMMY);
  }, [dataType]);

  const dataHandler = (id, menuTitle, payload) => {
    setData((items) => {
      return items.map((item, idx) => {
        if (idx === id) {
          return items[id].map((singleItem) => {
            if (singleItem.menuTitle === menuTitle) {
              return {
                ...singleItem,
                selected: payload,
              };
            }
            return singleItem;
          });
        }
        return item;
      });
    });
  };

  const addWorkerHandler = () => {
    setData((data) => [...data, ...EMPLOYEESDUMMY]);
  };

  const deleteWorkerHandler = (id) => {
    setData((data) => data.filter((_, employeeId) => employeeId !== id));
  };

  return (
    <Wrapper>
      <SmallTitle
        title="Send request"
        subtitle="You have succesfully signed up! Start to create your resume."
      />
      <Details text="Contract Details" />
      <Contract
        dataType={dataType}
        setDataType={setDataType}
        data={data}
        dataHandler={dataHandler}
        addWorkerHandler={addWorkerHandler}
        deleteWorkerHandler={deleteWorkerHandler}
      />
      <Comment />
      {!userStatus && <Contact />}

      <StyledButton text="Send Request" weight="medium" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 52%;
  margin: 0 auto;
`;

const StyledButton = styled(Button)`
  background-color: #2f80ed;
  padding: 12px 12px;
  margin-bottom: 80px;
  div {
    color: #fff;
    font-size: 16px;
    line-height: 24px;
  }
`;
