import styled from "styled-components";

import { Header } from "./Header";
import { RowMenus } from "./RowMenus";
import { Button } from "./AddButton";
import { Info } from "./Info";
import { INFODUMMY } from "./infoDummy";

export const Contract = (props) => {
  const {
    dataType,
    setDataType,
    data,
    dataHandler,
    addWorkerHandler,
    deleteWorkerHandler,
  } = props;
  const canDelete = data.length > 2;
  return (
    <Container>
      <Header setDataType={setDataType} />
      <Info {...INFODUMMY[dataType]} />
      {data.map((menuData, id) => (
        <RowMenus
          id={id}
          dataType={dataType}
          menuData={menuData}
          dataHandler={dataHandler}
          deleteWorkerHandler={() => deleteWorkerHandler(id)}
          canDelete={canDelete}
        />
      ))}
      {dataType === "team" && <Button onClick={addWorkerHandler} />}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;
