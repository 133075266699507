import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import styled from "styled-components";

import { Content } from "./Content";

SwiperCore.use([Pagination]);

export const ImgSlider = (props) => {
  const { data } = props;

  return (
    <div>
      <StyledSwiper
        id="main"
        wrapperTag="ul"
        pagination={{ clickable: true }}
        spaceBetween={0}
        slidesPerView={1}
      >
        {data.map((item, i) => {
          return (
            <SwiperSlide key={`slide-${i}`} tag="li">
              <img
                src={item.imgUrl}
                style={{ listStyle: "none" }}
                alt={`Slide ${i}`}
              />
              <Content {...item} />
            </SwiperSlide>
          );
        })}
      </StyledSwiper>
    </div>
  );
};

const StyledSwiper = styled(Swiper)`
  display: flex;
  ul {
    padding: 0;
    margin: 0;
    display: flex;
  }
  li {
    position: relative;
  }
  ul li img {
    width: 100%;
    height: 580px;
  }
  .swiper-pagination {
    bottom: 32px;
  }

  .swiper-pagination span.swiper-pagination-bullet-active {
    background-color: transparent;
    width: 6px;
    height: 6px;
    border: 3px solid green;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  div span {
    margin: 0 6px !important;
    background-color: #fff;
    opacity: 1;
  }
`;
