import styled from "styled-components";

import { Input } from "./Input";

export const InputsBlock = (props) => {
  const { fields, fieldsHandler, data, margin, className, ...rest } = props;

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    fieldsHandler(name, value);
  };

  return (
    <InputWrapper
      className={className}
      length={data.length % 2 == 0 ? data.length + 1 : data.length}
    >
      {data.map((item, index) => {
        const name = item.name;
        const value = fields[name];
        return (
          <StyledInput
            key={index}
            {...item}
            value={value}
            name={name}
            onChange={onChangeHandler}
            margin={!!margin ? margin : "24px"}
            {...rest}
          />
        );
      })}
    </InputWrapper>
  );
};

const StyledInput = styled(Input)`
  margin-bottom: ${(props) => props.margin};
  width: 49%;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  div:nth-child(n + ${(props) => props.length}) {
    width: 100%;
  }
`;
