export const Icon = (props) => {
  const { height, width, viewBox, fill, path, fillColor, className, ...rest } =
    props;
  return (
    <svg
      height={height}
      width={width}
      viewBox={viewBox}
      fill={fill}
      className={className}
      {...rest}
    >
      {path.map((item, index) => (
        <path
          key={index}
          d={item.d}
          fill={fillColor ? fillColor : item.fill}
          stroke={fillColor ? fillColor : item.stroke}
          strokeWidth={item.strokeWidth}
          fillRule={item.fillRule ? item.fillRule : "none"}
        />
      ))}
    </svg>
  );
};
