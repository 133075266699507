import { useState } from "react";
import styled from "styled-components";

import { Details } from "./Details";
import { Textarea } from "../../Components/Textarea";

export const Comment = () => {
  const [message, setMessage] = useState("");
  const fieldsHandler = (name, value) => {
    setMessage(value);
  };
  return (
    <Container>
      <Textarea
        name="message"
        value={message}
        fieldsHandler={fieldsHandler}
        placeholder="Type your message"
      />
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 40px;
  padding-top: 0px;
`;
