import { Title } from "./Title";
import { Container } from "../../Commons/Container";
import { CardList } from "./CardList";
export const OurService = () => {
  return (
    <Container>
      <Title />
      <CardList />
    </Container>
  );
};
