import styled from "styled-components";

import { CustomT } from "./CustomT";

export const SmallTitle = (props) => {
  const { title, subtitle, className } = props;
  return (
    <Container className={className}>
      <Title text={title} weight="medium" />
      <SubTitle text={subtitle} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0 64px;
  text-align: center;
  padding: 0 5px;
`;
const Title = styled(CustomT)`
  font-size: 28px;
  line-height: 34px;
  color: #000;
`;
const SubTitle = styled(CustomT)`
  font-size: 18px;
  line-height: 24px;
  color: #838383;
  margin-top: 8px;
`;
