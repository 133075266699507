import { Provider } from "react-redux";
import store from "./store";
import { RootNav } from "./navigation/RootNav";
function App() {
  return (
    <Provider store={store}>
      <RootNav />
    </Provider>
  );
}

export default App;
