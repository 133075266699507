import styled from "styled-components";
import { Icon } from "../../Components/Icon";
import { LOGO } from "../../assets/logo";
import { GETCONTRACT } from "../../assets/getcontract";
import { NavLink } from "react-router-dom";

export const Logo = () => {
  return (
    <NavLink to="/">
      <Container>
        <StyledIcon {...LOGO} />
        <Icon {...GETCONTRACT} />
      </Container>
    </NavLink>
  );
};

const Container = styled.div`
  width: 184px;
  height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledIcon = styled(Icon)`
  margin-right: 15px;
`;
