import { useState } from "react";

import styled from "styled-components";
import { Icon } from "../../Components/Icon";

export const SvgIcon = (props) => {
  const { item } = props;

  const [fill, setFill] = useState(null);

  const onMouseOverHandler = () => {
    setFill("#27AE60");
  };

  const onMouseLeaveHandler = () => {
    setFill(null);
  };
  return (
    <Wrapper
      onMouseOver={onMouseOverHandler}
      onMouseLeave={onMouseLeaveHandler}
    >
      <Icon {...item} fillColor={fill} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0 11px;
  cursor: pointer;
`;
