import styled from "styled-components";

import { CustomT } from "../../../Components/CustomT";

export const Comment = (props) => {
  const { comment, name, position } = props;
  return (
    <Wrapper>
      <CommentWrapper>
        <Text weight="regular" text={comment} />
        <InfoWrapper>
          <Img />
          <NameWrapper>
            <Name text={name} />
            <Position text={position} />
          </NameWrapper>
        </InfoWrapper>
      </CommentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Text = styled(CustomT)`
  font-size: 18px;
  line-height: 24px;
  color: #4f4f4f;
  font-style: italic;
  text-align: center;
  padding: 0 3px;
`;

const CommentWrapper = styled.div`
  max-width: 730px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InfoWrapper = styled.div`
  margin: 56px 0;
  display: flex;
`;

const Img = styled.div`
  width: 56px;
  height: 56px;
  background-color: #c4c4c4;
  border-radius: 50%;
`;
const NameWrapper = styled.div`
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Name = styled(CustomT)`
  font-size: 16px;
  line-height: 24px;
`;

const Position = styled(CustomT)`
  font-size: 14px;
  line-height: 24px;
  color: #828282;
`;
