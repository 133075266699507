import styled from "styled-components";

import { Input } from "../Input";

export const Inputs = (props) => {
  const { fields, fieldsHandler, data, register } = props;
  const onChange = (e) => {
    const { name, value } = e.target;
    fieldsHandler(name, value);
  };
  return (
    <>
      {data.map((item, index) => (
        <StyledInput
          key={index}
          {...item}
          value={fields[item.name]}
          onChange={onChange}
          register={register}
        />
      ))}
    </>
  );
};

const StyledInput = styled(Input)`
  width: ${(props) => (props.register ? "49%" : "100%")};
  margin-top: 16px;
`;
