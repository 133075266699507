import { useEffect, useState } from "react";
import styled from "styled-components";
import { CustomT } from "../../Components/CustomT";
import { Icon } from "../../Components/Icon";
import { DROPDOWN } from "../../assets/dropdown";

import OutsideClickHandler from "react-outside-click-handler";

export const DropDown = (props) => {
  const { menuTitle, type, selected, id, dataHandler, team } = props;
  const [menu, setMenu] = useState(false);
  const [borderColor, setBorderColor] = useState("#e0e0e0");

  const menuHandler = (e) => {
    e.stopPropagation();
    setMenu((menu) => {
      return !menu;
    });
  };

  useEffect(() => {
    !selected.includes("Select")
      ? setBorderColor("#333333")
      : setBorderColor("#e0e0e0");
  }, [selected]);

  const changeMenu = (text) => {
    dataHandler(id, menuTitle, text);
  };

  return (
    <Container onClick={menuHandler} width={team ? "40px" : "0px"}>
      <Title text={menuTitle} />
      <MenuContainer color={borderColor}>
        <SelectedText text={selected} />
        <StyledIcon {...DROPDOWN} />
        <StyledOutsideClickHandler onOutsideClick={() => setMenu(false)}>
          {menu && (
            <Menus>
              {type.map((text, id) => (
                <MenuItem
                  key={id}
                  text={text}
                  onClick={() => changeMenu(text)}
                />
              ))}
            </Menus>
          )}
        </StyledOutsideClickHandler>
      </MenuContainer>
    </Container>
  );
};

const Container = styled.div`
  width: calc((100% - 32px - ${(props) => props.width}) / 3);
  display: flex;
  flex-direction: column;
`;

const MenuContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 12px 16px;
  border: 1px solid ${(props) => props.color};
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  margin-top: 8px;
  position: relative;
`;

const Title = styled(CustomT)`
  font-size: 14px;
  line-height: 18px;
  color: #828282;
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 12px;
  right: 16px;
`;

const SelectedText = styled(CustomT)`
  font-size: 14px;
  line-height: 18px;
  color: #333;
`;

const StyledOutsideClickHandler = styled(OutsideClickHandler)`
  width: 0;
  margin: 0;
  padding: 0;
`;

const Menus = styled.div`
  width: 100%;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.75);
  top: 45px;
  left: 0;
  border-radius: 6px;
  padding-top: 5px;
  box-sizing: border-box;
  z-index: 100;
`;

const MenuItem = styled(CustomT)`
  margin-bottom: 2px;
  width: 100%;
  border-radius: 6px;
  padding: 5px 16px;
  box-sizing: border-box;
  :hover {
    background-color: #d9dddc;
  }
`;
