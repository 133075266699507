import styled from "styled-components";

import { DropDown } from "./DropDown";
import { BinIcon } from "./BinIcon";

export const RowMenus = (props) => {
  const {
    dataType,
    menuData,
    dataHandler,
    id,
    deleteWorkerHandler,
    canDelete,
  } = props;
  const team = dataType === "team";
  return (
    <Container>
      {menuData.map((item) => (
        <DropDown {...item} dataHandler={dataHandler} id={id} team={team} />
      ))}
      {team && (
        <BinIcon
          onClick={canDelete ? deleteWorkerHandler : undefined}
          canDelete={canDelete}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
  align-items: center;
`;
