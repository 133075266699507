import styled from "styled-components";
import { CustomT } from "./CustomT";
import { returnStyle } from "../utils/returnStyle";

import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";

export const Button = (props) => {
  const { text, styles, textStyle, weight, className, loading, ...rest } =
    props;

  return (
    <Container style={returnStyle(styles)} className={className} {...rest}>
      <CustomT weight={weight} text={text} style={returnStyle(textStyle)} />
      {loading && <ClipLoader color={"#2F80ED"} loading={loading} size={15} />}
    </Container>
  );
};

const Container = styled.div`
  border-radius: 4px;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 0;
    border-width: 3px;
    opacity: 1;
  }
`;

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
