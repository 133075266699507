import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { CustomT } from "../../../Components/CustomT";
import icon from "../../../assets/bx_bx-message-square-dots.png";

export const SendRequest = (props) => {
  const { className } = props;
  return (
    <Container className={className}>
      <Img src={icon} />
      <StyledNavLink to="/send-request">
        <CustomT text="Send Request" weight="medium" />
      </StyledNavLink>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: #2f80ed;
  cursor: pointer;
  :hover {
    box-shadow: none;
    background-color: #0a5ed0;
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 16px;
  color: white;
`;

const Img = styled.img`
  width: 20px;
  height: 20px;
  padding: 8px 8px 8px 16px;
`;
