import { useState, useEffect } from "react";
import styled from "styled-components";

import { CustomT } from "./CustomT";
import { checkText } from "../utils/textRegex";

export const Textarea = (props) => {
  const { value, fieldsHandler, placeholder } = props;
  const [bordercolor, setBordercolor] = useState("#e0e0e0");
  const [invalid, setInvalid] = useState(false);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    fieldsHandler(name, value);
  };

  useEffect(() => {
    if (value.trim() !== "") setInvalid(checkText(value));
  }, [value]);

  useEffect(() => {
    invalid
      ? setBordercolor("red")
      : !!value && value.trim() !== ""
      ? setBordercolor("#828282")
      : setBordercolor("#e0e0e0");
  }, [value, invalid]);

  return (
    <Container>
      <Text text="Your message" />
      <TextArea
        bordercolor={bordercolor}
        value={value}
        name="message"
        onChange={onChangeHandler}
        placeholder={placeholder}
      />
      {invalid && <ValidationText text="Enter message" />}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const Text = styled(CustomT)`
  font-size: 14px;
  line-height: 18px;
  color: #828282;
  margin-bottom: 8px;
  cursor: default;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 164px;
  box-sizing: border-box;
  padding: 12px 16px;
  border-radius: 6px;
  border: 1px solid ${(props) => props.bordercolor};
  font-family: "Roboto";
  font-size: 14px;
  line-height: 18px;
  color: #333;
  resize: none;

  :focus {
    outline: none;
    border: 1px solid ${(props) => props.bordercolor};
  }
`;

const ValidationText = styled(CustomT)`
  font-size: 12px;
  color: red;
  margin-top: 4px;
`;
