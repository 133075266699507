import { useEffect, useState } from "react";
import styled from "styled-components";

import { CustomT } from "../../Components/CustomT";
import { Button } from "../../Components/Button";

export const Header = (props) => {
  const { setDataType } = props;
  const [buttons, setButtons] = useState([
    {
      text: "Candidate",
      active: true,
      type: "worker",
    },
    {
      text: "Team",
      active: false,
      type: "team",
    },
    {
      text: "IT Service",
      active: false,
      type: "service",
    },
  ]);

  const buttonHandler = (text, type) => {
    setButtons((buttons) =>
      buttons.map((button) => {
        if (button.text === text) {
          return {
            ...button,
            active: true,
          };
        }
        return { ...button, active: false };
      })
    );

    setDataType(type);
  };

  return (
    <Wrapper>
      <Text text="Who do you need ?" weight="medium" />
      <ButtonWrapper>
        {buttons.map((button) => {
          return (
            <StyledButton
              {...button}
              weight="medium"
              styles={button.active ? activeButton : {}}
              onClick={() => buttonHandler(button.text, button.type)}
            />
          );
        })}
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  margin-top: 16px;
`;

const Text = styled(CustomT)`
  font-size: 16px;
  line-height: 18px;
  color: #828282;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledButton = styled(Button)`
  padding: 8px 16px;
  border: 1px solid #bdbdbd;
  margin-left: 12px;
  color: #828282;
`;

const activeButton = { backgroundColor: "#27AE60", color: "#fff" };
