import styled from "styled-components";

import { Service } from "./Service";
import { Image } from "./Image";

import { DUMMY } from "./serviceTexts";

export const CardList = () => {
  return (
    <Wrapper>
      {DUMMY.map((item, index) => {
        return index % 2 === 0 ? (
          <>
            <Image {...item} key={index} />
            <Service {...item} id={index + 1} />
          </>
        ) : (
          <>
            <Service {...item} id={index + 1} key={index} />
            <Image {...item} />
          </>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;
