import { useState, useEffect } from "react";

import styled from "styled-components";

import { CustomT } from "./CustomT";
import { checkEmail } from "../utils/emailRegex";
import { checkPhone } from "../utils/phoneRegex";
import { checkText } from "../utils/textRegex";
import { checkPassword } from "../utils/passwordRegex";

export const Input = (props) => {
  const { className, title, value, name, type, invalidMessage, ...rest } =
    props;

  const [invalid, setInvalid] = useState(false);
  const [borderColor, setBordercolor] = useState("#e0e0e0");

  useEffect(() => {
    if (type === "email" && value.trim() !== "") {
      setInvalid(checkEmail(value));
    }
    if (type === "phone" && value.trim() !== "") {
      setInvalid(checkPhone(value));
    }
    if (type === "text" && value.trim() !== "") {
      setInvalid(checkText(value));
    }
    if (type === "password" && value.trim() !== "") {
      setInvalid(checkPassword(value));
    }
  }, [value]);

  useEffect(() => {
    invalid
      ? setBordercolor("red")
      : !!value && value.trim() !== ""
      ? setBordercolor("#828282")
      : setBordercolor("#e0e0e0");
  }, [invalid, value]);

  return (
    <Container className={className}>
      <Text text={title} />
      <StyledInput
        type={type}
        value={value}
        name={name}
        autoComplete="off"
        {...rest}
        borderColor={borderColor}
        focusBorderColor={borderColor}
      />
      {invalid && <ValidationText text={invalidMessage} />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  width: 48%;
`;

const Text = styled(CustomT)`
  font-size: 14px;
  line-height: 18px;
  color: #828282;
  margin-bottom: 8px;
  cursor: default;
`;

const StyledInput = styled.input`
  padding: 12px 16px;
  border-radius: 6px;
  border: 1px solid ${(props) => props.borderColor};
  font-size: 14px;
  line-height: 18px;
  color: #333;
  :focus {
    outline: none;
    border: 1px solid ${(props) => props.focusBorderColor};
  }
`;

const ValidationText = styled(CustomT)`
  font-size: 12px;
  color: red;
  margin-top: 4px;
`;
