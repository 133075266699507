import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import styled from "styled-components";

import { Comment } from "./Comment";

SwiperCore.use([Pagination]);

export const SwipperContainer = (props) => {
  const { data } = props;
  return (
    <StyledSwiper
      id="main"
      tag="section"
      wrapperTag="ul"
      pagination={{ clickable: true }}
      spaceBetween={0}
      slidesPerView={1}
    >
      {data.map((item, index) => (
        <SwiperSlide key={index} tag="li">
          <Comment {...item} />
        </SwiperSlide>
      ))}
    </StyledSwiper>
  );
};

const StyledSwiper = styled(Swiper)`
  ul {
    padding: 0;
    margin: 56px 0;
    list-style: none;
  }
  .swiper-pagination-bullets {
    bottom: 0;
    span {
      background-color: #e0e0e0;
      opacity: 1;
    }
  }
  .swiper-pagination-bullets {
    .swiper-pagination-bullet-active {
      background-color: #27ae60;
    }
  }
`;
