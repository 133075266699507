import { Home } from "../pages/HomePage";
import { OurService } from "../pages/OurServices";
import { Contact } from "../pages/Contact";

export const TABS = [
  {
    name: "Home",
    to: "/",
    tab: Home,
  },
  {
    name: "Our services",
    to: "/our-services",
    tab: OurService,
  },
  {
    name: "Contact",
    to: "/contact",
    tab: Contact,
  },
];
