export const LOGO = {
  width: "64",
  height: "44",
  fill: "none",
  viewBox: "0 0 64 44",
  path: [
    {
      fill: "#2F80ED",
      stroke: "none",
      strokeWidth: 0,
      d: "M8.82335 11.0056C10.4016 5.70473 11.1907 3.05429 13.2387 1.52714C15.2868 0 18.0522 0 23.5831 0H53.1983C58.604 0 61.3068 0 62.6265 1.76982C63.9462 3.53965 63.1749 6.13012 61.6324 11.3111L55.1767 32.9944C53.5985 38.2953 52.8094 40.9457 50.7613 42.4729C48.7133 44 45.9478 44 40.417 44H10.8018C5.39607 44 2.69323 44 1.37355 42.2302C0.0538775 40.4604 0.825132 37.8699 2.36764 32.6889L8.82335 11.0056Z",
    },
    {
      fill: "#F3F3F3",
      stroke: "none",
      strokeWidth: 0,
      d: "M17.7001 11C17.7001 9.78497 18.685 8.8 19.9001 8.8H51.8001C53.0151 8.8 54.0001 9.78497 54.0001 11C54.0001 12.215 53.0151 13.2 51.8001 13.2H19.9001C18.685 13.2 17.7001 12.215 17.7001 11Z",
    },
    {
      fill: "#F3F3F3",
      stroke: "none",
      strokeWidth: 0,
      d: "M13.3 20.9C13.3 19.685 14.285 18.7 15.5 18.7H47.4001C48.6151 18.7 49.6001 19.685 49.6001 20.9C49.6001 22.115 48.6151 23.1 47.4001 23.1H15.5C14.285 23.1 13.3 22.115 13.3 20.9Z",
    },
    {
      fill: "#8DFFC8",
      stroke: "none",
      strokeWidth: 0,
      d: "M30.35 35.75C30.35 34.535 31.3349 33.55 32.55 33.55H42.45C43.665 33.55 44.65 34.535 44.65 35.75C44.65 36.965 43.665 37.95 42.45 37.95H32.55C31.3349 37.95 30.35 36.965 30.35 35.75Z",
    },
  ],
};
