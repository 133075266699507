import { fbApp } from "../firebaseInit";

export const sendContact = async (payload) => {
  const { phone, name } = payload;
  const path = `contacts/${phone}`;

  await fbApp.dbRef.ref(path).once("value", async (snapshot) => {
    if (snapshot.exists()) {
      const data = snapshot.val();

      await fbApp.dbRef.ref(path).set([...data, payload]);
    } else {
      fbApp.dbRef.ref(path).set([payload]);
    }
  });
  return !!(await fbApp.dbRef.ref(path).once("value")).val();
};
