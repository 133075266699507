import styled from "styled-components";
import { NavBarLink } from "../../Components/NavBarLink";

export const Navigation = () => {
  const DUMMY = [
    {
      to: "/",
      name: "Home",
    },
    {
      to: "/our-service",
      name: "Our Service",
    },
    {
      to: "/contact",
      name: "Contact",
    },
  ];

  const scrollToUp = (name) => {
    if (name === "Home") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    return;
  };

  return (
    <Container>
      {DUMMY.map((item, index) => (
        <Link
          key={index}
          to={item.to}
          name={item.name}
          active={{ backgroundColor: "transparent" }}
          onClick={() => scrollToUp(item.name)}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
`;

const Link = styled(NavBarLink)`
  background-color: transparent;
  padding: 6px 16px;
  margin: 0;

  div {
    color: #f2f2f2;
    font-size: 14px;
    line-height: 24px;
  }
`;
