import { useEffect } from "react";

import { ImgSlider } from "./Slider/ImgSlider";
import { OurService } from "./OurService";
import { OurSuccesses } from "./OurSuccesses";
import img1 from "../../assets/slider1.png";
import { OurPartners } from "./OurPartners";
import { HowItWorks } from "./HowItWorks";
import { FeedBacks } from "./FeedBacks";
import { useUserState } from "../../hooks/getUserState";

export const Home = () => {
  const status = useUserState();
  const DUMMY = [
    {
      imgUrl: img1,
      text: "We are The Best to Hire Right Candidates with Simple Steps.",

      navText: "Send Request",
      to: "/send-request",
    },
    {
      imgUrl: img1,
      text: "Create Team and Finish Outstanding Projects Perfectly on Time.",

      navText: "Send Request",
      to: "send-request",
    },
    {
      imgUrl: img1,
      text: "Do not Struggle with Technical Stuff and Leave IT to us.",

      navText: "Send Request",
      to: "send-request",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);

  return (
    <>
      <ImgSlider data={DUMMY} />
      <OurService />
      <OurSuccesses />
      <OurPartners />
      <HowItWorks />
      <FeedBacks />
    </>
  );
};
