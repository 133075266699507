export const DUMMY = [
  {
    name: "email",
    title: "Email",
    type: "email",
    invalidMessage: "Please enter correct email",
  },
  {
    name: "password",
    title: "Password",
    type: "password",
    invalidMessage: "Enter at least 6 caracter",
  },
];
