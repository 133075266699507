import { useEffect } from "react";

import styled from "styled-components";

import { Container } from "../../Commons/Container";
import { Titles } from "../../Components/Titles";
import { CustomT } from "../../Components/CustomT";
import { DUMMY } from "./serviceTexts";

export const ServiceTemplate = (props) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const id = +props?.match?.params?.id;

  const { title, subtitle, textTitle, texts, img } = DUMMY.find(
    (item) => item.id === id
  );
  return (
    <Container>
      <Title title={title} subtitle={subtitle} />
      <Img src={img} />
      <Wrapper>
        <TextTitle text={textTitle} weight="bold" />
        {texts.map((item, index) => (
          <Text key={index} text={item} />
        ))}
      </Wrapper>
    </Container>
  );
};

const Title = styled(Titles)`
  margin: 40px 0 60px;
`;

const Img = styled.img`
  width: 100%;
  height: 403px;
  object-fit: cover;
`;

const Wrapper = styled.div`
  margin: 30px 0 80px;
  background-color: #f4f6f8;
  box-sizing: border-box;
  padding: 60px;
`;

const TextTitle = styled(CustomT)`
  font-size: 24px;
  line-height: 30px;
  color: #333333;
  max-width: 50%;
`;

const Text = styled(CustomT)`
  font-size: 18px;
  line-height: 30px;
  margin-top: 50px;
`;
