import styled from "styled-components";

export const Row = (props) => {
  const { style, className } = props;
  return (
    <Container style={style} className={className}>
      {props.children}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
