import { useEffect } from "react";

import styled from "styled-components";
import { Container } from "../../../Commons/Container";
import { Titles } from "../../../Components/Titles";
import { CardList } from "./CardList";

const DUMMY = [
  {
    title: "Get Candidate",
    subtitle:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis quis molestiae vitae eligendi at.",
    to: "/service/1",
  },
  {
    title: "Get Team",
    subtitle:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis quis molestiae vitae eligendi at.",
    to: "/service/2",
  },
  {
    title: "Get IT Services",
    subtitle:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis quis molestiae vitae eligendi at.",
    to: "/service/3",
  },
];

export const OurService = () => {
  return (
    <StyledContainer>
      <Titles
        title="Our Services"
        subtitle="We help to our customers to execute their technical projects with professional experts."
      />
      <CardList data={DUMMY} />
    </StyledContainer>
  );
};
const StyledContainer = styled(Container)`
  margin: 80px 0;
`;
