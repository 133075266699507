import styled from "styled-components";
import { Container } from "../../../Commons/Container";
import { Titles } from "../../../Components/Titles";
import { PartnerList } from "./PartnerList";

export const OurPartners = () => {
  return (
    <StyledContainer>
      <Titles
        titleColor="#333333"
        title="Our Partners"
        subtitle="Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis quis molestiae vitae eligendi at."
      />
      <PartnerList />
    </StyledContainer>
  );
};
const StyledContainer = styled(Container)`
  padding: 80px 0;
`;
