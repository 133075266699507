export const EMPLOYEESDUMMY = [
  [
    {
      menuTitle: "Worker",
      type: ["iOS developer", "Android developer", "QA"],
      selected: "Select Worker",
    },
    {
      menuTitle: "Employee",
      type: ["Junior", "Middle", "Senior"],
      selected: "Select Level",
    },
    {
      menuTitle: "Contract duration",
      type: ["1 year", "3 months", "unlimited"],
      selected: "Select period",
    },
  ],
];

export const SERVICEDUMMY = [
  [
    {
      menuTitle: "Service",
      type: ["It service", "Loyalty"],
      selected: "Select Service",
    },
    {
      menuTitle: "Cloud",
      type: ["Onprimise", "Cloud"],
      selected: "Select type",
    },
    {
      menuTitle: "Contract duration",
      type: ["1 year", "3 months", "unlimited"],
      selected: "Select period",
    },
  ],
];
