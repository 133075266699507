export const CONTACTS = [
  {
    height: "14px",
    width: "14px",
    fill: "",
    viewBox: "0 0 14 14",
    path: [
      {
        fill: "#F2F2F2",
        stroke: "none",
        strokeWidth: 0,
        d: "M13.2222 9.72222C12.25 9.72222 11.3167 9.56667 10.4456 9.27889C10.3088 9.23533 10.1628 9.22969 10.0231 9.26256C9.88338 9.29543 9.75521 9.36559 9.65222 9.46556L7.94111 11.1767C5.73315 10.0537 3.9385 8.25908 2.81556 6.05111L4.52667 4.33222C4.62968 4.23449 4.70269 4.1094 4.73713 3.97164C4.77157 3.83388 4.76601 3.68916 4.72111 3.55444C4.42606 2.65849 4.27639 1.72106 4.27778 0.777778C4.27778 0.35 3.92778 0 3.5 0H0.777778C0.35 0 0 0.35 0 0.777778C0 8.08111 5.91889 14 13.2222 14C13.65 14 14 13.65 14 13.2222V10.5C14 10.0722 13.65 9.72222 13.2222 9.72222ZM12.4444 7H14C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0V1.55556C10.01 1.55556 12.4444 3.99 12.4444 7ZM9.33333 7H10.8889C10.8889 4.85333 9.14667 3.11111 7 3.11111V4.66667C8.29111 4.66667 9.33333 5.70889 9.33333 7Z",
      },
    ],
  },
  {
    height: "14px",
    width: "12px",
    fill: "",
    viewBox: "0 0 14 12",
    path: [
      {
        fill: "#F2F2F2",
        stroke: "none",
        strokeWidth: 0,
        d: "M14 3.2256V8.925C14 9.5061 13.7777 10.0652 13.3786 10.4876C12.9795 10.9099 12.434 11.1636 11.8538 11.1965L11.725 11.2H2.275C1.6939 11.2 1.13482 10.9777 0.712438 10.5786C0.290057 10.1795 0.0363991 9.63396 0.00350008 9.0538L0 8.925V3.2256L6.7564 6.7648C6.83156 6.80417 6.91515 6.82474 7 6.82474C7.08485 6.82474 7.16844 6.80417 7.2436 6.7648L14 3.2256ZM2.275 1.65236e-08H11.725C12.2889 -6.79103e-05 12.8327 0.209297 13.251 0.587487C13.6693 0.965677 13.9322 1.48574 13.9888 2.0468L7 5.7078L0.0112 2.0468C0.0654664 1.50802 0.310135 1.00626 0.701225 0.631724C1.09231 0.257186 1.60418 0.0344309 2.1448 0.0035001L2.275 1.65236e-08H11.725H2.275Z",
      },
    ],
  },
];
