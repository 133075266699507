import styled from "styled-components";
import { Card } from "./Card";
import { Row } from "../../../Components/Row";
import { ICONS } from "../../../assets/icons";

export const CardList = () => {
  const DUMMY = [
    {
      icon: ICONS.success1,
      title: "4 Years of Experienc",
      subtitle:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Et praesentium eos nulla qui commodi consectetur beatae fugiat. Veniam iste rerum perferendis.",
    },
    {
      icon: ICONS.success2,
      title: "More than 15 partners",
      subtitle:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Et praesentium eos nulla qui commodi consectetur beatae fugiat. Veniam iste rerum perferendis.",
    },
    {
      icon: ICONS.success3,
      title: "60+ Work Contracts",
      subtitle:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Et praesentium eos nulla qui commodi consectetur beatae fugiat. Veniam iste rerum perferendis.",
    },
  ];
  return (
    <StyledRow>
      {DUMMY.map((item, index) => (
        <Card key={index} {...item} />
      ))}
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  margin: 72px -15px 0;
`;
