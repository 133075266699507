import styled from "styled-components";
import { Row } from "../../../Components/Row";
import { Card } from "./Card";

export const CardList = () => {
  const DUMMY = [
    {
      step: 1,
      stepTitle: "Step one",
      stepSubtitle:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Et praesentium eos nulla qui commodi consectetur beatae fugiat. Veniam iste rerum perferendis.",
    },
    {
      step: 2,
      stepTitle: "Step two",
      stepSubtitle:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Et praesentium eos nulla qui commodi consectetur beatae fugiat. Veniam iste rerum perferendis.",
    },
    {
      step: 3,
      stepTitle: "Step three",
      stepSubtitle:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Et praesentium eos nulla qui commodi consectetur beatae fugiat. Veniam iste rerum perferendis.",
    },
  ];
  return (
    <StyledRow>
      {DUMMY.map((item, index) => (
        <Card key={index} {...item} />
      ))}
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  margin: 0 -15px;
`;
