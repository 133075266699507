import styled from "styled-components";

import { Titles } from "../../Components/Titles";

export const Title = () => {
  return (
    <Text
      title="Our Services"
      subtitle="We help to our customers to execute their technical projects with professional experts."
    />
  );
};

const Text = styled(Titles)`
  box-sizing: border-box;
  padding: 40px 0 60px;
`;
