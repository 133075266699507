import styled from "styled-components";

import { Icon } from "../../Components/Icon";
import { ADD } from "../../assets/add";
import { CustomT } from "../../Components/CustomT";

export const Button = (props) => {
  const { ...rest } = props;
  return (
    <Container {...rest}>
      <Icon {...ADD} fillColor={"#fff"} />
      <Text text="Add team member" weight="medium" />
    </Container>
  );
};

const Container = styled.div`
  margin-top: 28px;
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  background-color: #27ae60;
  border-radius: 4px;
  width: fit-content;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: #34c371;
  }
`;

const Text = styled(CustomT)`
  color: #fff;
  margin-left: 10.5px;
`;
