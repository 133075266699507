export const GETCONTRACT = {
  width: "105px",
  height: "42px",
  fill: "none",
  viewBox: "0 0 105 42",
  path: [
    {
      fill: "#032A58",
      stroke: "none",
      strokeWidth: 0,
      d: "M15.9517 2.67751L13.5436 5.03579C13.0399 4.4638 12.4166 4.00963 11.7178 3.70547C11.0191 3.40131 10.2619 3.25461 9.50004 3.27578C6.43378 3.27578 4.21012 5.52441 4.21012 8.79011C4.21012 12.2154 6.54347 14.3592 9.44022 14.3592C12.1674 14.3592 13.7579 13.043 14.2017 10.6598H9.94379V7.87268H17.6469C17.6831 8.18544 17.6998 8.50022 17.6967 8.81506C17.6967 13.8906 14.2615 17.4703 9.51003 17.4703C4.86824 17.4703 0.75 14.1997 0.75 8.79011C0.75 3.72952 4.44946 0.104882 9.59481 0.104882C10.7755 0.0647027 11.9514 0.272878 13.0465 0.716057C14.1415 1.15924 15.1313 1.82749 15.9517 2.67751Z",
    },
    {
      fill: "#032A58",
      stroke: "none",
      strokeWidth: 0,
      d: "M31.2531 11.3527C31.2535 11.7043 31.2335 12.0556 31.1933 12.4048H22.9717C23.0699 13.1117 23.4341 13.7544 23.99 14.2019C24.5459 14.6494 25.2516 14.868 25.9632 14.813C27.0387 14.7879 28.0732 14.3956 28.8948 13.7011L30.7246 15.6955C29.3656 16.8962 27.5958 17.5265 25.7837 17.4553C22.2288 17.4553 19.7609 15.0373 19.7609 11.3877C19.7609 7.73805 22.154 5.24512 25.6242 5.24512C28.9547 5.26008 31.2431 7.73802 31.2531 11.3527ZM22.9817 10.2758H28.1171C27.8329 8.78009 26.9304 7.9525 25.5942 7.9525C24.1184 7.9525 23.2609 8.83993 22.9817 10.2858V10.2758Z",
    },
    {
      fill: "#032A58",
      stroke: "none",
      strokeWidth: 0,
      d: "M42.0922 8.35142H37.8942V12.5844C37.8942 14.0352 38.662 14.5787 39.6293 14.5787C40.4084 14.5204 41.1591 14.261 41.808 13.8258L42.9548 16.2688C41.8374 17.0735 40.4927 17.5013 39.1157 17.4903C36.2439 17.4903 34.7681 15.8599 34.7681 12.8585V8.37126H32.5345V5.59426H34.7681V2.14899H37.8942V5.59426H42.0922V8.35142Z",
    },
    {
      fill: "#032A58",
      stroke: "none",
      strokeWidth: 0,
      d: "M16.7295 28.2737L13.8527 30.0285C13.3915 29.3034 12.7506 28.7099 11.9923 28.3054C11.2341 27.901 10.3841 27.6994 9.525 27.7202C6.48366 27.7202 4.23507 29.9488 4.23507 33.2046C4.23507 36.57 6.56842 38.6889 9.525 38.6889C10.3876 38.7044 11.2402 38.5018 12.0037 38.1C12.7671 37.6983 13.4168 37.1102 13.8926 36.3904L16.6198 38.3549C15.825 39.4838 14.7628 40.3979 13.5281 41.0157C12.2935 41.6334 10.9249 41.9355 9.54496 41.8948C4.38964 41.8948 0.75 38.2452 0.75 33.2046C0.75 28.1639 4.38964 24.5193 9.54496 24.5193C12.6362 24.5143 15.3634 25.9253 16.7295 28.2737Z",
    },
    {
      fill: "#032A58",
      stroke: "none",
      strokeWidth: 0,
      d: "M30.8792 35.7772C30.8792 39.2673 28.2118 41.8798 24.5971 41.8798C20.9824 41.8798 18.3399 39.2673 18.3399 35.7772C18.3399 32.2871 20.9973 29.6696 24.5971 29.6696C28.1968 29.6696 30.8792 32.2821 30.8792 35.7772ZM21.5259 35.7772C21.5484 36.3816 21.7483 36.9661 22.1006 37.4577C22.453 37.9493 22.9424 38.3264 23.5076 38.5418C24.0728 38.7572 24.6888 38.8014 25.279 38.6691C25.8692 38.5368 26.4075 38.2337 26.8266 37.7976C27.2457 37.3614 27.5271 36.8116 27.6359 36.2166C27.7447 35.6216 27.676 35.0078 27.4382 34.4516C27.2005 33.8954 26.8043 33.4215 26.2991 33.0889C25.7939 32.7563 25.2019 32.5798 24.5971 32.5813C24.1814 32.5753 23.769 32.6547 23.3853 32.8148C23.0017 32.9749 22.6551 33.2122 22.367 33.5119C22.079 33.8116 21.8557 34.1675 21.711 34.5572C21.5662 34.9469 21.5033 35.3621 21.5259 35.7772Z",
    },
    {
      fill: "#032A58",
      stroke: "none",
      strokeWidth: 0,
      d: "M44.3907 34.1968V41.5808H41.2696V35.1939C41.2696 33.5187 40.432 32.5963 39.0061 32.5963C37.6649 32.5963 36.553 33.4738 36.553 35.2189V41.5758H33.432V29.9788H36.4683V31.3399C36.846 30.7923 37.3578 30.3507 37.9549 30.0574C38.552 29.7641 39.2143 29.6289 39.8786 29.6646C40.48 29.6375 41.0805 29.7364 41.6416 29.9548C42.2026 30.1732 42.7119 30.5064 43.1367 30.9331C43.5615 31.3598 43.8925 31.8705 44.1084 32.4325C44.3244 32.9945 44.4205 33.5954 44.3907 34.1968Z",
    },
    {
      fill: "#032A58",
      stroke: "none",
      strokeWidth: 0,
      d: "M55.8681 32.7609H51.665V36.9938C51.665 38.4447 52.4328 38.9881 53.4001 38.9881C54.1792 38.9298 54.9299 38.6704 55.5788 38.2353L56.7256 40.6783C55.6082 41.4829 54.2635 41.9107 52.8865 41.8998C50.0147 41.8998 48.5439 40.2694 48.5439 37.2679V32.7807H46.3053V30.0037H48.5439V26.5584H51.665V30.0037H55.8681V32.7609Z",
    },
    {
      fill: "#032A58",
      stroke: "none",
      strokeWidth: 0,
      d: "M66.5376 29.9239L66.074 33.025C65.4741 32.7797 64.8324 32.6527 64.1843 32.6511C62.7335 32.6511 61.7413 33.5386 61.7413 35.2587V41.5807H58.6152V29.9838H61.6466V31.2502C62.0066 30.7256 62.4971 30.3039 63.0698 30.0267C63.6425 29.7496 64.2775 29.6264 64.9122 29.6696C65.4647 29.6591 66.0148 29.7452 66.5376 29.9239Z",
    },
    {
      fill: "#032A58",
      stroke: "none",
      strokeWidth: 0,
      d: "M79.6902 41.5807H76.6388V40.2993C76.2211 40.8171 75.6877 41.2298 75.0816 41.5042C74.4755 41.7787 73.8136 41.9073 73.1488 41.8798C69.9529 41.8798 67.5348 39.2572 67.5348 35.7772C67.5348 32.2971 69.9529 29.6695 73.1488 29.6695C73.8149 29.643 74.478 29.7736 75.0843 30.0508C75.6906 30.3279 76.2231 30.744 76.6388 31.2651V29.9837H79.6902V41.5807ZM70.7507 35.7772C70.7507 37.5471 71.9123 38.9681 73.7421 38.9681C75.5719 38.9681 76.7336 37.6069 76.7336 35.7772C76.7336 33.9474 75.527 32.5813 73.7421 32.5813C71.9572 32.5813 70.7507 34.0022 70.7507 35.7772Z",
    },
    {
      fill: "#032A58",
      stroke: "none",
      strokeWidth: 0,
      d: "M93.0671 31.8634L90.8185 33.7381C90.514 33.3734 90.1326 33.0804 89.7016 32.8804C89.2706 32.6805 88.8007 32.5784 88.3256 32.5814C86.7102 32.5814 85.4188 33.8477 85.4188 35.7772C85.4188 37.7067 86.7002 38.9682 88.3156 38.9682C88.7951 38.9624 89.2678 38.8549 89.7027 38.6529C90.1376 38.451 90.5247 38.1591 90.8384 37.7965L93.0671 39.7011C92.5151 40.4029 91.8063 40.9655 90.9976 41.3439C90.1889 41.7223 89.3028 41.9058 88.4103 41.8798C84.7109 41.8798 82.2379 39.2922 82.2379 35.7772C82.2379 32.2622 84.7308 29.6696 88.4103 29.6696C89.3047 29.6407 90.1933 29.8244 91.0028 30.2058C91.8124 30.5872 92.5199 31.1553 93.0671 31.8634Z",
    },
    {
      fill: "#032A58",
      stroke: "none",
      strokeWidth: 0,
      d: "M103.861 32.7609H99.6633V36.9938C99.6633 38.4447 100.426 38.9881 101.398 38.9881C102.176 38.929 102.925 38.6696 103.572 38.2353L104.724 40.6783C103.605 41.4839 102.258 41.9118 100.88 41.8998C98.008 41.8998 96.5372 40.2694 96.5372 37.2679V32.7807H94.2986V30.0037H96.5372V26.5584H99.6633V30.0037H103.861V32.7609Z",
    },
  ],
};
