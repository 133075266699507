import styled from "styled-components";
import { CustomT } from "../../Components/CustomT";
export const Details = (props) => {
  const { text } = props;
  return <Text text={text} weight="medium" />;
};

const Text = styled(CustomT)`
  font-size: 22px;
  line-height: 24px;
  color: #333;
`;
