export const DELETE = {
  width: "20px",
  height: "20px",
  viewBox: "0 0 20 20",
  fill: "none",
  path: [
    {
      fill: "#FF0000",
      stroke: "none",
      strokeWidth: "0",
      d: "M14 5.2H18V6.8H16.4V17.2C16.4 17.4122 16.3157 17.6157 16.1657 17.7657C16.0157 17.9157 15.8122 18 15.6 18H4.4C4.18783 18 3.98434 17.9157 3.83431 17.7657C3.68429 17.6157 3.6 17.4122 3.6 17.2V6.8H2V5.2H6V2.8C6 2.58783 6.08429 2.38434 6.23431 2.23431C6.38434 2.08429 6.58783 2 6.8 2H13.2C13.4122 2 13.6157 2.08429 13.7657 2.23431C13.9157 2.38434 14 2.58783 14 2.8V5.2ZM14.8 6.8H5.2V16.4H14.8V6.8ZM7.6 9.2H9.2V14H7.6V9.2ZM10.8 9.2H12.4V14H10.8V9.2ZM7.6 3.6V5.2H12.4V3.6H7.6Z",
    },
  ],
};
