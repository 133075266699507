import styled from "styled-components";

import { SmallTitle } from "../SmallTitle";
import { SignInSignUp } from "../SignInSignUp";
import { Inputs } from "./Inputs";
import { Error } from "../Error";
import { TEXTS } from "./Texts";

export const Card = (props) => {
  const {
    fields,
    fieldsHandler,
    active,
    loading,
    error,
    data,
    location,
    ...rest
  } = props;
  return (
    <Wrapper register={location === "register"}>
      <Title
        title={TEXTS[location].title}
        subtitle={TEXTS[location].subTitle}
      />

      <Error errorMessage={error.errorMessage} opened={error.status} />
      <InputWrapper>
        <Inputs
          fields={fields}
          fieldsHandler={fieldsHandler}
          data={data}
          register={location === "register"}
        />
      </InputWrapper>

      <Login
        btnName={TEXTS[location].btnName}
        navName={TEXTS[location].navigateTo}
        to={TEXTS[location].path}
        {...rest}
        bgColor={active ? "#27AE60" : "#e0e0e0"}
        loading={loading}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: ${(props) => (props.register ? "66%" : "33%")};
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 48px 32px 32px;
  display: flex;
  flex-wrap: ${(props) => (props.register ? "wrap" : "nowrap")};
  flex-direction: column;
  margin: 23px auto;
`;

const Title = styled(SmallTitle)`
  margin: 0 0 16px;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Login = styled(SignInSignUp)`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;
