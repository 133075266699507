export const ICONS = {
  success1: {
    height: "44px",
    width: "44px",
    fill: "none",
    viewBox: "0 0 44 44",
    path: [
      {
        fill: "#27AE60",
        stroke: "none",
        strokeWidth: 0,
        d: "M22 0C9.85029 0 0 9.85029 0 22C0 34.1497 9.85029 44 22 44C34.1497 44 44 34.1497 44 22C44 9.85029 34.1497 0 22 0ZM32.1538 25.3846H22C21.5512 25.3846 21.1207 25.2063 20.8034 24.889C20.486 24.5716 20.3077 24.1411 20.3077 23.6923V8.46154C20.3077 8.01271 20.486 7.58227 20.8034 7.2649C21.1207 6.94753 21.5512 6.76923 22 6.76923C22.4488 6.76923 22.8793 6.94753 23.1966 7.2649C23.514 7.58227 23.6923 8.01271 23.6923 8.46154V22H32.1538C32.6027 22 33.0331 22.1783 33.3505 22.4957C33.6679 22.813 33.8462 23.2435 33.8462 23.6923C33.8462 24.1411 33.6679 24.5716 33.3505 24.889C33.0331 25.2063 32.6027 25.3846 32.1538 25.3846Z",
      },
    ],
  },
  success2: {
    height: "36px",
    with: "44px",
    fill: "none",
    viewBox: "0 0 44 36",
    path: [
      {
        fill: "#27AE60",
        stroke: "none",
        strokeWidth: 0,
        d: "M33.5515 13.1985H23.1015V17.0485C23.1015 19.7779 20.8809 21.9985 18.1515 21.9985C15.4221 21.9985 13.2015 19.7779 13.2015 17.0485V8.6885L8.73963 11.3697C7.41276 12.1604 6.60151 13.5972 6.60151 15.1372V18.3891L1.10152 21.5653C0.0496472 22.1703 -0.314727 23.5178 0.297147 24.5697L5.79714 34.0984C6.40214 35.1503 7.74963 35.5078 8.80151 34.9028L15.9102 30.7985H25.3015C27.7283 30.7985 29.7015 28.8253 29.7015 26.3985H30.8015C32.0183 26.3985 33.0015 25.4153 33.0015 24.1985V19.7985H33.5515C34.4658 19.7985 35.2015 19.0629 35.2015 18.1485V14.8485C35.2015 13.9341 34.4658 13.1985 33.5515 13.1985ZM43.7058 10.6272L38.2058 1.09851C37.6008 0.0466377 36.2533 -0.310862 35.2015 0.294137L28.0927 4.3985H21.0665C20.2415 4.3985 19.4371 4.63225 18.7359 5.06538L16.4327 6.50225C15.7865 6.901 15.4015 7.60912 15.4015 8.36537V17.0485C15.4015 18.5679 16.6321 19.7985 18.1515 19.7985C19.6709 19.7985 20.9015 18.5679 20.9015 17.0485V10.9985H33.5515C35.6758 10.9985 37.4015 12.7241 37.4015 14.8485V16.8079L42.9014 13.6316C43.9533 13.0197 44.3108 11.6791 43.7058 10.6272Z",
      },
    ],
  },
  success3: {
    height: "40px",
    width: "35px",
    fill: "none",
    viewBox: "0 0 35 40",
    path: [
      {
        fill: "#27AE60",
        stroke: "none",
        strokeWidth: 0,
        fillRule: "evenodd",
        d: "M6.10352e-05 4C6.10352e-05 2.93913 0.421489 1.92172 1.17163 1.17157C1.92178 0.421427 2.9392 0 4.00006 0L25.8854 0L34.6667 8.78133V36C34.6667 37.0609 34.2453 38.0783 33.4952 38.8284C32.745 39.5786 31.7276 40 30.6667 40H4.00006C2.9392 40 1.92178 39.5786 1.17163 38.8284C0.421489 38.0783 6.10352e-05 37.0609 6.10352e-05 36V4ZM8.00006 10.6587L21.3334 10.6667V13.3333L8.00006 13.3253V10.6587ZM26.6667 18.6533H8.00006V21.32H26.6667V18.6533ZM26.6667 26.6693L8.00006 26.648V29.3147L26.6667 29.3333V26.6667V26.6693Z",
      },
    ],
  },
};
