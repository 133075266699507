import styled from "styled-components";

import { AccountDetail } from "./Account";
import { ExitButton } from "./Exit";
import { Tabs } from "./Tabs";

export const LeftSide = (props) => {
  return (
    <Left>
      <AccountDetail />
      <Tabs />
      <ExitButton />
    </Left>
  );
};

const Left = styled.div`
  background-color: #fff;
  width: 31%;
  border-radius: 8px;
  height: fit-content;
`;
