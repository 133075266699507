import styled from "styled-components";
import { CustomT } from "../../../Components/CustomT";
import { Icon } from "../../../Components/Icon";

export const Card = (props) => {
  const { title, subtitle, icon } = props;
  return (
    <Wrapper>
      <ImgWrapper>
        <Icon {...icon} />
      </ImgWrapper>
      <StyledTitle weight="medium" text={title} />
      <StyledSubTitle text={subtitle} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 350px;
  height: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin: 0 15px 0 15px;
`;

const ImgWrapper = styled.div`
  width: 88px;
  height: 88px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0px 20px 20px rgba(33, 150, 83, 0.05);
`;

const StyledTitle = styled(CustomT)`
  font-size: 22px;
  line-height: 25.78px;
  color: #333333;
  margin: 40px 0 24px 0;
`;

const StyledSubTitle = styled(CustomT)`
  font-size: 18px;
  line-height: 26px;
  color: #828282;
  padding: 0 5px 0 5px;
`;
