export const ADD = {
  width: "20px",
  height: "20px",
  fill: "none",
  viewBox: "0 0 20 20",
  path: [
    {
      fill: "#333333",
      stroke: "#333333",
      strokeWidth: "0.2",
      d: "M10.5127 6.36235C10.3767 6.22638 10.1923 6.15 10 6.15C9.80772 6.15 9.62331 6.22638 9.48735 6.36235C9.35138 6.49831 9.275 6.68272 9.275 6.875V9.275H6.875C6.68272 9.275 6.49831 9.35138 6.36235 9.48735C6.22638 9.62331 6.15 9.80772 6.15 10C6.15 10.1923 6.22638 10.3767 6.36235 10.5127C6.49831 10.6486 6.68272 10.725 6.875 10.725H9.275V13.125C9.275 13.3173 9.35138 13.5017 9.48735 13.6377C9.62331 13.7736 9.80772 13.85 10 13.85C10.1923 13.85 10.3767 13.7736 10.5127 13.6377C10.6486 13.5017 10.725 13.3173 10.725 13.125V10.725H13.125C13.3173 10.725 13.5017 10.6486 13.6377 10.5127C13.7736 10.3767 13.85 10.1923 13.85 10C13.85 9.80772 13.7736 9.62331 13.6377 9.48735C13.5017 9.35138 13.3173 9.275 13.125 9.275H10.725V6.875C10.725 6.68272 10.6486 6.49831 10.5127 6.36235ZM2.97852 7.09161C2.59658 8.01368 2.4 9.00195 2.4 10C2.4 12.0156 3.20071 13.9487 4.62599 15.374C6.05126 16.7993 7.98435 17.6 10 17.6C12.0156 17.6 13.9487 16.7993 15.374 15.374C16.7993 13.9487 17.6 12.0156 17.6 10C17.6 9.00195 17.4034 8.01368 17.0215 7.09161C16.6395 6.16953 16.0797 5.33171 15.374 4.62599C14.6683 3.92026 13.8305 3.36045 12.9084 2.97852C11.9863 2.59658 10.998 2.4 10 2.4C9.00195 2.4 8.01368 2.59658 7.09161 2.97852C6.16953 3.36045 5.33171 3.92026 4.62599 4.62599C3.92026 5.33171 3.36045 6.16953 2.97852 7.09161ZM5.65129 5.65129C6.80464 4.49794 8.36892 3.85 10 3.85C11.6311 3.85 13.1954 4.49794 14.3487 5.65129C15.5021 6.80464 16.15 8.36892 16.15 10C16.15 11.6311 15.5021 13.1954 14.3487 14.3487C13.1954 15.5021 11.6311 16.15 10 16.15C8.36892 16.15 6.80464 15.5021 5.65129 14.3487C4.49794 13.1954 3.85 11.6311 3.85 10C3.85 8.36892 4.49794 6.80464 5.65129 5.65129Z",
    },
  ],
};
