import styled from "styled-components";
import { Icon } from "../../../Components/Icon";

export const Partner = (props) => {
  const { icon } = props;
  return (
    <Container>
      <Icon {...icon} />
    </Container>
  );
};

const Container = styled.div`
  width: 160px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px;
`;
