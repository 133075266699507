import styled from "styled-components";
import { Container } from "./Container";
import { Titles } from "../Components/Titles";

export const ContainerWithTitle = (props) => {
  const { children, title, subtitle } = props;
  return (
    <Container>
      <Wrapper>
        <TextWrapper>
          <Text title={title} subtitle={subtitle} />
        </TextWrapper>
        {children}
      </Wrapper>
    </Container>
  );
};

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 40px 190px 80px;
  position: relative;
`;

const TextWrapper = styled.div`
  width: 100%;
`;

const Text = styled(Titles)`
  margin-bottom: 64px;
  div {
    max-width: 730px;
    padding: 0 5px;
  }
`;
