export const TEXTS = {
  signin: {
    title: "Log In",
    subTitle: "You have succesfully signed up! Start to create your resume.",
    btnName: "Login",
    navigateTo: "Register",
    path: "register",
  },
  register: {
    title: "Register",
    subTitle: "You have succesfully signed up! Start to create your resume.",
    btnName: "Register",
    navigateTo: "Sign In",
    path: "login",
  },
};
