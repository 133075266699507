import styled from "styled-components";

import { CustomT } from "../../../Components/CustomT";
import { ProfileCircle } from "../../../Components/ProfileCircle";

export const AccountDetail = () => {
  return (
    <Account>
      <ProfileCircle
        fullName={"Fuad Huseynli"}
        width="56px"
        size="24px"
        statusCircle={true}
      />
      <Name text={`Fuad Huseynli`} weight="medium" />
      <Email text={"fuad201685@gmail.com"} />
    </Account>
  );
};

const Account = styled.div`
  padding: 24px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const Name = styled(CustomT)`
  font-size: 18px;
  line-height: 24px;
  color: #333;
  margin-top: 16px;
`;

const Email = styled(CustomT)`
  font-size: 14px;
  line-height: 16.41px;
  color: #828282;
  margin-top: 4px;
`;
