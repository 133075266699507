import styled from "styled-components";
import { CustomT } from "./CustomT";

export const Titles = (props) => {
  const { title, subtitle, titleColor, className } = props;

  const titleStyle = {
    color: titleColor ? titleColor : "#27AE60",
    fontSize: "48px",
    lineHeight: "56.25px",
  };

  return (
    <Container className={className}>
      <Wrapper>
        <CustomT text={title} weight="bold" style={titleStyle} />
        <CustomT weight="regular" style={subTitleStyle} text={subtitle} />
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 540px;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
`;

const subTitleStyle = {
  textAlign: "center",
  fontSize: "18px",
  lineHeight: "26px",
  color: "#333333",
  marginTop: "24px",
};
