import { useState, useEffect } from "react";
import { connect } from "react-redux";

import { SignCard } from "../../Components/SignCard/Index";
import { DUMMY } from "./dummy";

import { checkPassword } from "../../utils/passwordRegex";
import { checkEmail } from "../../utils/emailRegex";
import { getErrorMessage } from "../../utils/getErrorMessage";

import { login } from "../../store/auth";

export const SingIn = connect(null, { login })(({ login, history }) => {
  const [fields, setFields] = useState({
    email: "",
    password: "",
  });

  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ status: false, errorMessage: "" });

  useEffect(() => {
    const valid = !checkPassword(fields.password) && !checkEmail(fields.email);
    valid ? setActive(true) : setActive(false);
    setError({ status: false });
  }, [fields]);

  const fieldsHandler = (name, value) => {
    setFields((fields) => ({
      ...fields,
      [name]: value,
    }));
  };

  const loginHandler = async () => {
    if (active) {
      setLoading(true);
      const answer = await login({
        email: fields.email,
        password: fields.password,
      });

      if (!!answer?.code) {
        setLoading(false);
        const errorMessage = getErrorMessage(answer.code);
        setError({ status: true, errorMessage });
        return;
      }
      history.push("profile");
      setLoading(false);
    }
  };

  return (
    <SignCard
      fields={fields}
      fieldsHandler={fieldsHandler}
      onClick={loginHandler}
      active={active}
      loading={loading}
      error={error}
      data={DUMMY}
      location="signin"
    />
  );
});
