import firebase from "firebase";

export const MODULE_NAME = "auth";

export const selectUser = (state) => state[MODULE_NAME].user;
export const selectAuthStatus = (state) => state[MODULE_NAME].user.status;
export const selectUserFullName = (state) =>
  `${state[MODULE_NAME].user.name} ${state[MODULE_NAME].user.lastName}`;
export const selectUserInfo = (state) => {
  const info = state[MODULE_NAME].user;
  const { name, lastName, phone, email } = info;
  return { name, lastName, phone, email };
};

const SET_USER = "SET_USER";
const LOGIN = "LOGIN";

const initialState = {
  user: {},
};

export function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_USER:
      return {
        ...state,
        user: payload,
      };
    case LOGIN:
      return {
        ...state,
        user: payload,
      };
    default:
      return state;
  }
}

export const setUserAction = (payload) => ({
  type: SET_USER,
  payload,
});

export const loginAction = (payload) => ({
  type: LOGIN,
  payload,
});

export const setUser = (data) => async (dispatch) => {
  const { name, lastName, email, phone, company, position, password } = data;
  try {
    const {
      user: { uid },
    } = await firebase.auth().createUserWithEmailAndPassword(email, password);
    const path = `users/${uid}`;

    await firebase.database().ref(path).set({
      name,
      lastName,
      email,
      company,
      position,
      phone,
      createdTime: Date.now(),
    });

    if (!!uid) {
      dispatch(
        setUserAction({
          id: uid,
          name,
          lastName,
          company,
          email,
          position,
          phone,
          status: true,
          password,
        })
      );
    }
  } catch (error) {
    return error;
  }
};

export const login = (payload) => async (dispatch) => {
  try {
    const {
      user: { uid },
    } = await firebase
      .auth()
      .signInWithEmailAndPassword(payload.email, payload.password);

    const path = `users/${uid}`;

    const { name, lastName, position, company, phone, email } = await (
      await firebase.database().ref(path).once("value")
    ).val();

    dispatch(
      loginAction({
        status: true,
        id: uid,
        name,
        lastName,
        company,
        position,
        phone,
        email,
        password: payload.password,
      })
    );
  } catch (error) {
    return error;
  }
};

export const resetPassword = (newPassword) => async (dispatch, getState) => {
  const user = firebase.auth().currentUser;
  const userInfo = selectUser(getState());
  dispatch(
    loginAction({
      ...userInfo,
      password: newPassword,
    })
  );

  return user
    .updatePassword(newPassword)
    .then(() => "success")
    .catch(() => "error");
};
