import styled from "styled-components";
import { Container } from "../../../Commons/Container";
import { Titles } from "../../../Components/Titles";
import { CardList } from "./CardList";

export const OurSuccesses = () => {
  return (
    <StyledContainer bgColor="#F4F8F6">
      <Titles
        title="Our Successes"
        subtitle="Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis quis molestiae vitae eligendi at."
        titleColor="#333333"
      />
      <CardList />
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  padding: 80px 0;
`;
