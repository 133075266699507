import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyDfLVQZWmO52dEjmO16t8O-5cDp7yw_hNE",
  authDomain: "getcontractwebsite.firebaseapp.com",
  databaseURL: "https://getcontractwebsite-default-rtdb.firebaseio.com",
  projectId: "getcontractwebsite",
  storageBucket: "getcontractwebsite.appspot.com",
  messagingSenderId: "1064536571409",
  appId: "1:1064536571409:web:2786ad8ba7db7c533d3bb0",
  measurementId: "G-RZ55LZPY0M",
};

firebase.initializeApp(firebaseConfig);
export const fbApp = {
  dbRef: firebase.database(),
};
