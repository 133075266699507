import styled from "styled-components";

export const Container = (props) => {
  const { bgColor, style, className } = props;
  return (
    <Body bgColor={bgColor} style={style} className={className}>
      <Wrapper>{props.children}</Wrapper>
    </Body>
  );
};

const Body = styled.div`
  width: 100%;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#fff")};
`;

const Wrapper = styled.div`
  width: calc(100% - 22%);
  display: flex;
  flex-direction: column;
  margin: auto;
`;
