import styled from "styled-components";

import { CustomT } from "../../Components/CustomT";
import { Icon } from "../../Components/Icon";
import { CONTACTS } from "../../assets/contacts";

export const Contact = () => {
  return (
    <Container>
      {CONTACTS.map((item, index) => {
        return (
          <Wrapper key={index}>
            <Icon {...item} />
            <Text text="+1 234 5678 9101" />
          </Wrapper>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 31px;
`;

const Text = styled(CustomT)`
  font-size: 14px;
  line-height: 24px;
  color: #f2f2f2;
  margin-left: 9px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 8px;
`;
