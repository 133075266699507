import { Route } from "react-router";
import { SIGNTABS } from "./SingIn&UpPages";

export const SignRoutes = () => {
  return (
    <>
      {SIGNTABS.map((item, index) => {
        const TagName = item.tab;

        return <Route key={index} exact path={item.to} component={TagName} />;
      })}
    </>
  );
};
