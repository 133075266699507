import { useLocation } from "react-router-dom";

import { AccountDetails } from "./AccountDetails";
import { Notification } from "./Notification";
import { Requests } from "./Requests";

export const RightSide = (props) => {
  const { user } = props;
  const { pathname } = useLocation();

  switch (pathname) {
    case "/profile":
      return <AccountDetails user={user} />;
    case "/profile/notifications":
      return <Notification />;
    case "/profile/requests":
      return <Requests />;
    default:
      return <AccountDetails />;
  }
};
